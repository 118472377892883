import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Index from './pages/index'
import Home from './pages/home'
import Invite from './pages/invite'
import Task from './pages/task'
import Proxy from './pages/proxy';
import Settings from './pages/settings';
import FundFlow from './pages/fundflow';
import Dashboard from './pages/dashboard';
import Bindings from './pages/bindings';
import TransactionLog from './pages/transaction_log';
import Resetpassword from './pages/resetpassword';
import Resetsubpassword  from './pages/resetsubpassword';
import Transfer from './pages/transfer';
import Withdraw from './pages/withdraw';
import WithdrawRecord from './pages/withdraw_record'; 
import Webwhitelistsettings from './pages/webwhitelistsettings';
import GAVerification from './pages/ga_verification';
import NewTask from './pages/newtask';
import Paytype from './pages/paytype';
import WithdrawDepositOffer from './pages/withdraw_deposit_offer';

function App() {
  
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact>
          <Index />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/invite">
          <Invite />
        </Route>
        <Route path="/task">
          <Task />
        </Route>
        <Route path="/proxy">
          <Proxy />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/fundflow">
          <FundFlow />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/bindings">
          <Bindings />
        </Route>
        <Route path="/transaction-log">
          <TransactionLog />
        </Route>
        <Route path="/resetpassword">
          <Resetpassword />
        </Route>
        <Route path="/resetsubpassword">
          <Resetsubpassword />
        </Route>
        <Route path="/transfer">
          <Transfer />
        </Route>
        <Route path="/newtask">
          <NewTask />
        </Route>
        <Route path="/paytype">
          <Paytype/>
        </Route>
        <Route path="/withdraw">
          <Withdraw />
        </Route>
        <Route path="/withdrawrecord">
          <WithdrawRecord />
        </Route>
        <Route path="/webwhitelistsettings">
          <Webwhitelistsettings />
        </Route>
        <Route path="/ga_verification">
          <GAVerification />
        </Route>
        <Route path="/withdraw_deposit_offer">
          <WithdrawDepositOffer />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
