import { InfiniteScroll, Toast } from 'antd-mobile/2x';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import http from '../lib/http'
import useSWR from 'swr';
import Header from "../components/header"
import './task.scss'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Store from 'store'

const Task = () => {
    const {t} = useTranslation()

    const [page, setPage] = useState(1)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(1)
    const [popup, setPopup] = useState(0)
    const {data:listData, error: err, mutate}  = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/task?limit=10&page=${page}`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})

    const loadMore = useCallback(() => {
        setPage(parseInt(page + 1));
    }, [page])

    function del(id){
        console.log(id)
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        http({ url: `${process.env.REACT_APP_API_URL}/agentbackend/task/${id}`, method: 'delete'})
            .then(res => {
                if(res.errorCode!==undefined){
                    alert(t('notinwhitelist'));
                    Store.remove('user')
                    window.location.href = '/'
                }
                if (res.status === 0) {
                    Toast.clear()
                    window.location.reload(false);
                    //mutate(`${process.env.REACT_APP_API_URL}/agentbackend/task?limit=10&page=${page}`)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.message,
                        maskClickable: false
                    })
                }
            })
            .catch(() => {
                Toast.clear()
            })
    }

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }
        console.log(err);

        if (listData && listData.status === 0) {
            console.log(listData);
            const { data: {data, total_page, current_page} } = listData
            setTotal(total_page);
            if (current_page=== 1) {
                setList([...data])
            } else {
                setList(v => [...v, ...data]);
            }
        }

    }, [listData, err]);

    return (
        <div className="task d-flex">
            <style></style>
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-2 vh-100 overflow-auto position-relative">
            <h4 className='text-dark-blue'>{t('list3Title')}</h4>
            <div className='bg-white'>
              <TableContainer component={Paper}>
                    <Table className="w-100" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>{t('No')}</TableCell>
                            <TableCell className="text-center">{t('type')}</TableCell>
                            <TableCell className="text-center">{t('state')}</TableCell>
                            <TableCell className="text-center">{t('unit')}</TableCell>
                            <TableCell align="right"><span className="px-4 pt-2 pb-2 rounded-1">{t('edit')}</span></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {list.map((item) => (
                            <TableRow
                            key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {item.id}
                                </TableCell>
                                <TableCell className="text-center">{item.type === 1 ? t('sell') :t('buy')}</TableCell>
                                <TableCell className={item.deleted_at ? 'del-font text-center': 'text-center'}>{item.deleted_at ? t('del') :t('online')}</TableCell>
                                <TableCell className="text-center">{item.goal}</TableCell>
                                <TableCell align="right">
                                    {!item.deleted_at ? 
                                        <button className="bg-dark-blue text-white border-0 px-4 pt-2 pb-2 rounded-1" onClick={()=>setPopup(item.id)}>{t('del_btn')}</button> : ''}
                                </TableCell>
                            </TableRow>

                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            {popup > 0 && (
                <div className='position-absolute top-0 start-0 w-100 h-100' style={{backgroundColor:"rgba(0,0,0,0.8"}}>
                    <div className='position-absolute p-4 border-2 border-dark-blue bg-white rounded-2' style={{ top:'50%',left:'50%',transform: `translate(-50%,-50%)` }}>
                        <div className='d-flex flex-column gap-4'>
                            <h2 className='text-center p-4'>{t('confirmorderdelection')} {popup}</h2>
                            <div className='d-flex justify-content-between gap-4'>
                                <button className="bg-white fw-bolder text-dark-blue border-1 border-dark-blue px-4 pt-2 pb-2 rounded-1" onClick={()=>setPopup(0)}>{t('cancel')}</button>
                                <button className="bg-dark-blue text-white border-0 px-4 pt-2 pb-2 rounded-1" onClick={()=>del(popup)}>{t('confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* <button className="bg-dark-blue text-white border-0 px-4 pt-2 pb-2 rounded-1" onClick={del.bind(this, item.id)}>{t('del_btn')}</button> */}

            {/*  <InfiniteScroll loadMore={loadMore} hasMore={total-page > 0} /> */}
            </div>
            
        </div>
    )
}

export default Task;