import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import http from '../lib/http'

import Header from "../components/header"
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next'
import Store from 'store'

import "react-datepicker/dist/react-datepicker.css";
import "../App.scss"


function createData(id,amount,total_amount,fee,subject,time) {
    return { id, amount, total_amount, fee, subject,time };
}

const WithdrawRecord = () => {
    const { t } = useTranslation();
    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/transaction`;
    const callback_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/callback`;
    const [data,setData] = useState([]);
    const { search } = useLocation();

    const [startDate, setStartDate] = useState(); 
    const [endDate, setEndDate] = useState(); 
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const [order_query, setOrderQuery] = useState('');
    const [username_query, setUsernameQuery] = useState('');

    const [network_query, setNetworkQuery] = useState('');
    const [address_query, setAddressQuery] = useState('');
    const [status_query, setStatusQuery] = useState(-1);

    // useMemo(() => getDirection(search), [search]);
    useEffect(async() => {
        updateTableDate(page,rowsPerPage);
    },[]);

    async function updateTableDate(_page,_rowsPerPage){
        var params = {
            // "network":network_query,
            // "point_address":address_query,
            // "status":status_query,
            "startDate":startDate,
            "endDate":endDate,
            "page":parseInt(_page)+1,
            "limit":_rowsPerPage,
            "type":2,
        };
        if(params['startDate'] != null){
            params['startDate'] = new Date(params['startDate'].setHours(0,0,0,0));
            params['startDate'] = new Date(params['startDate'].getTime() + (8*60*60*1000));
        }
        if(params['endDate'] != null){
            params['endDate'] = new Date(params['endDate'].setHours(23,59,0,0));
            params['endDate'] = new Date(params['endDate'].getTime() + (8*60*60*1000));
        }
       
        let data = await http({ url: url, method: 'post',params:params}).then(response=>{
            // updateTableDate(response.data.lists.data);
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.data.lists!=0){
                const temp_row = response.data.lists.data;
                setRows(temp_row);
                setTotalRowCount(response.data.lists.total_rows_count);
                setTotalAmount(response.data.amount);
                setTotalFee(response.data.fee);
            }
        });  
    }

    // function getDirection(_search){
    //     const p = new URLSearchParams(_search);
    //     setDirection(p.get('direction'));
    //     updateTableDate(p.get('direction'),page,rowsPerPage);
    // }

    // const updateTableDate = (_data) => {
    //     const temp_row = _data;
    //     setRows(temp_row);
    // }

    const handleChangePage = (event, newPage) => {
        console.log('newpage',newPage);
        setPage(newPage);
        updateTableDate(newPage,rowsPerPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        console.log('handleChangeRowsPerPage',event.target.value)
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        updateTableDate(0,event.target.value);
      };

    function timeZoneConvert(date){
        var d = new Date(date);
        return d.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong', hour12: false })
    }

    function saveDataInCSV(fileName = 'export.csv'){
        const data = JSON.parse(JSON.stringify(rows));

        if (data.length === 0) {
          console.warn('Data is empty.');
          return;
        }

        data.map((item) => {
            item[t('withdrawordernumber')] = item['sn']  ;
            delete item['sn'] ;

            item[t('createtime')] = timeZoneConvert(item['created_at'])  ;
            delete item['created_at'] ;

            item[t('withdrawcurrency')] = item['network'] ;
            item[t('withdrawaddress')] = item['point_address'] ;
            delete item['network'] ;
            delete item['point_address'] ;


            item[t('currencynumber')] = item['total_amount'] ;
            delete item['total_amount'] ;

            item[t('fee')] = item['fee']  ;
            delete item['fee'] ;

            item[t('approvalstatus')] = item['status'] == 0?"审核中":"提现成功" ;
            delete item['status'] ;

            item[t('withdrawhash')] = item['txid'];
            delete item['txid'] ;

            delete item['type'] ;
            delete item['user_id'] ;
            delete item['message'];
            delete item['blocknumer'];
            delete item['retry_num'];
            delete item['updated_at'];
            delete item['submitted_amount'];
            delete item['submitted_txid'];
            delete item['name'];
            delete item['bank'];
            delete item['rate'];
        });

      
        const propertyNames = Object.keys(data[0]);
        const rowWithPropertyNames = propertyNames.join(',') + '\n';
      
        let csvContent = rowWithPropertyNames;
      
        const r = [];
      
        data.forEach((item) => {
          const values = [];
      
          propertyNames.forEach((key) => {
            let val = item[key];
      
            if (val !== undefined && val !== null) {
              val = new String(val);
            } else {
              val = '';
            }
            values.push(escapeCsvValue(val));
          });
      
          r.push(values.join(','));
        });
      
        csvContent += r.join('\n');
      
        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a link element and trigger a download
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
      
          // Append the link to the body
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else {
          console.error('Download is not supported in this browser.');
        }

        console.log(rows);
        console.log(data);
      }
    
      function escapeCsvValue(value){
        // If the value contains a comma, newline, or double quote, wrap it in double quotes
        if (value.includes(',') || value.includes('\n') || value.includes('"')) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      }

      function erc20AddressShortForm(_address){
        if(_address.length<12){
            return _address;
        }
        let shortAddress = _address.substring(0, 6) + "..." + _address.substring(_address.length-6);
        return shortAddress;
      }

      function copyToClipboard(text){
        navigator.clipboard.writeText(text);
      }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4 className="text-dark-blue">{t('withdrawrecord')}</h4>
                <div className='w-100 d-flex justify-content-between gap-2 flex-column flex-xl-row'>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('withdrawcurrency')}</span>
                        <input className="w-100" value={order_query} name="order_query" placeholder="TRC" onChange={(e)=>setOrderQuery(e.target.value)}/>
                    </div>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('withdrawaddress')}</span>
                        <input className="w-100" value={address_query} name="address_query" placeholder={t('withdrawaddress')} onChange={(e)=>setAddressQuery(e.target.value)}/>
                    </div>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('approvalstatus')}</span>
                        <input className="w-100" value={username_query} name="username_query" placeholder={t('underreview')} onChange={(e)=>setUsernameQuery(e.target.value)}/>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                        <span style={{minWidth:70}}>{t('timequery')}</span>
                        <div style={{width:100}}>
                            <DatePicker className="w-100" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                        <span>{t('to')}</span>
                        <div style={{width:100}}>
                            <DatePicker className="w-100" selected={endDate} onChange={(date) => setEndDate(date)} />
                        </div>
                        <button className="bg-dark-blue text-white px-3 rounded-2 border-0 fs-6" onClick={()=>updateTableDate(page,rowsPerPage)}>查询</button>
                    </div>
                    <div className='d-flex gap-2 align-items-center border border-2 border-secondary p-2' role="button" onClick={()=>saveDataInCSV()}>
                        <i className="fa-solid fa-download"></i>
                        <span>{t('downloadlist')}</span>
                    </div>
                </div>
                
                <div className="h-100 overflow-auto">
                    <TableContainer component={Paper}>
                        <Table className="w-100" aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">{t('withdrawordernumber')}</TableCell>
                                <TableCell align="right">{t('createtime')}</TableCell>
                                <TableCell align="right">{t('withdrawcurrency')}</TableCell>
                                <TableCell align="right">{t('withdrawaddress')}</TableCell>
                                <TableCell align="right">{t('currencynumber')}</TableCell>
                                <TableCell align="right">{t('fee')}</TableCell>
                                <TableCell align="right">{t('approvalstatus')}</TableCell>
                                <TableCell align="right">{t('withdrawhash')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row,index) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">{index+1}</TableCell>
                                <TableCell component="th" scope="row">{row.sn}</TableCell>
                                <TableCell align="right">{timeZoneConvert(row.created_at)}</TableCell>
                                <TableCell align="right">{row.network}</TableCell>
                                <TableCell align="right" >
                                    <div className='d-flex flex-row gap-2 justify-content-end align-items-center'>
                                        <span>{erc20AddressShortForm(row.point_address)}</span>
                                        <i className="fa-solid fa-copy" role="button" onClick={()=>copyToClipboard(row.point_address)}></i>
                                    </div>
                                </TableCell>
                                <TableCell align="right">{row.total_amount}</TableCell>
                                <TableCell align="right">{row.fee}</TableCell>
                                <TableCell align="right">{row.status == 0?t('underreview'):t('withdrawsuccess')}</TableCell>
                                <TableCell align="right">
                                    <div className='d-flex flex-row gap-2 justify-content-end align-items-center'>
                                        {row.txid?erc20AddressShortForm(row.txid):""}
                                        {row.txid && <i className="fa-solid fa-copy" role="button" onClick={()=>copyToClipboard(row.txid)}></i>}
                                    </div>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow className='border border-top'>
                                    <TableCell component="th" scope="row">{t('total')}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{totalAmount}</TableCell>
                                    <TableCell align="right">{totalFee}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={9}
                                    count={totalRowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t('everypageshow')}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                                </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default WithdrawRecord