const en = {
    title: "V Wallet Admin Panel",
    account: "Username",
    plz_account: "Please input username",
    pwd: "Password",
    plz_pwd: "Please input password",
    login: "Login",
    plz_ga: "Please input 2-factor code",
    ga: "2-factor code(if any)",
    forget: "Forgot Password？",
    find: "Please go to V Wallet to proceed",
    admin: "Merchant Centre",
    out: "Logout",
    list1Title: "Invite Friends",
    list1Detail: "Invite Friends List",
    list2Title: "Merchant Info",
    list2Detail: "代理的信息列表",
    list3Title: "Order Management",
    list3Detail: "My Order",
    list4Title: "Settings",
    list4Detail: "API Settings",
    total: "Total Number",
    num: "Remaining balance",
    invite_id: "Invitee ID",
    type: "Type",
    in_time: "Register Date",
    No: "No",
    state:  "Status",
    edit: "Edit",
    buy: "Buy",
    sell: "Sell",
    online: "Online",
    del: "Deleted",
    del_btn: "Delete",
    level: "Level",
    directNum: "No. of Referral",
    directMoney: "No. of Referral Amount",
    proxy_total: "Proxy Number",
    proxy_total_money: "Total V币",
    agent: "Merchant",
    boss: "Boss",
    player: "Player",
    welcome_message: "Welcome",
    token_input: "API Secret",
    token_placeholder: "Click to get API secret",
    name_input: "API Name",
    name_placeholder: "Input Name",
    whitelist_input: "IP Whitelist",
    whitelist_placeholder: "IP Whitelist",
    dashboard:"Dashboard",
    changeavatar:"Change Avatar",
    loginname:"Username",
    accountoverview:"Account Overview",
    walletaddress:"Wallet Address",
    numberofrechargeusers:"No. of deposit users",
    numberofusersissued:"No. of withdraw users",
    user:"User",
    username:"Username",
    realname:"Real Name",
    address:"Address",
    wallet:"Wallet",
    balance:"Balance",
    secondary:"Sub",
    available:"Available",
    frozen:"Frozen",
    amount:"Amount",
    myrate:"My Rate",
    issuance:"Withdraw",
    deposit:"Deposit",
    transfer:"Transfer",
    withdrawal:"Withdraw",
    transactions:"Orders",
    quantity:"Quantity",
    playerrecharge:"Player Deposit(Amount/Commission)",
    playerwithdrawals:"Player Withdraw(Amount/Commission)",
    merchantcashwithdrawal:"My Withdrawal(Amount/Commission)",
    merchanttransfer:"Merchant Transfer(Amount/Commission)",
    totalamountcollectedtoday:"Today Collected Amount",
    todaytotalwithdrawalamount:"Today Released Amount",
    todayrechargeamount:"Today Deposit Amount",
    volumeissuedtoday:"Today Withdrawal Amount",
    balanceinpendingorder:"Order Amount",
    transactionamount:"Transaction Amount",
    withdrawrecord:"Withdrawal Records",
    userbinding:"User Binding",
    walletflow:"Wallet Flow",
    pendingordermanagement:"Order Management",
    pendingorder:"My Order",
    myinvitation:"My Invitation",
    setting:"Settings",
    operation:"Operation",
    whitelistsettings:"Whitelist Settings",
    resetpassword:"Change Login Password",
    resetsubpassword:"Change Payment Password",
    subpassword:"Payment Password",
    gapassword:"2-factor code",
    gapassword_ifany:"2-factor code(if any)",
    signout:"Logout",
    manualissuanceofapplication:"Manual User withdrawal",
    merchanttransfer:"Merchant Transfer",
    memberaddress:"Member Address",
    recipientaddress:"Receipant Address",
    inputwalletaddress:"Please input eceipant Address",
    quantityissued:"Amount",
    quantitytransfer:"Amount",
    quantitywithdraw:"Amount",
    cashwithdraw:"Amount",
    fee:"Fee",
    submit:"Submit",
    reset:"Reset",
    inquiry:"Search",
    transfersuccess:"Transfer Success",
    gapasswordincorrect:"2-factor code incorrect",
    subpasswordincorrect:"Payment password incorrect",
    transferfailed:"Transfer failed. Please retry",
    withdrawfailed:"Withdraw failed. Please retry",
    withdrawsuccess:"Withdrawal succeess",
    pleaseinputwalletaddress:"Please input wallet address",
    pleaseinputtransferamount:"Please input amount",
    pleaseinputsubpassword:"Please input payment password",
    pleaseinputgapassword:"Please input 2-factor code",
    pleaseinputbranchname:"Please input bank code",
    pleaseinputname:"Please input real name",
    pleaseinputaccountnumber:"Please input account number",
    pleaseinputoldpassword:"Please input old password",
    pleaseinputnewpassword:"Please input new password",
    pleaseconfirmnewpassword:"Please confirm new password",
    pleaseinputoldsubpassword:"Please input old payment password",
    pleaseinputnewsubpassword:"Please input new payment password",
    pleaseconfirmnewsubpassword:"Please confirm new payment password",
    rmb:"RMB",
    type:"Type",
    todayexchangerate:"Today Exchange Rate",
    amountcanwithdraw:"Available Balance",
    withdrawall:"Withdraw all",
    branchname:"Bank Name",
    name:"Real Name",
    accountnumber:"Account Number",
    minwithdraw:"Min. withdraw amount",
    withdrawordernumber:"Withdraw Order Number",
    withdrawcurrency:"Withdraw Currency",
    withdrawaddress:"Withdrawl Address",
    approvalstatus:"Approval Status",
    underreview:"Under Review",
    timequery:"Time Enquiry",
    to:"to",
    total:"Total",
    downloadlist:"Download",
    createtime:"Create Date",
    updatetime:"Update Date",
    currencynumber:"Amount(Currency)",
    withdrawhash:"Transaction Hash",
    everypageshow:"Show on ",
    remarks:"Remark",
    numberofcoins:"Amount(V币)",
    totalbalance:"Total Balance",
    merchanttransferto:"Transfer to you",
    merchanttransferout:"You transfer out",
    systemreturns:"Systems return",
    merchantreceivesrecharge:"Deposit",
    merchantwithdrawscurrency:"Withdraw",
    administratortransfersin:"Admin tranfer to you",
    administratortransfersout:"You transfer to admin",
    ordernumber:"Order Number",
    orderstatus:"Order Status",
    callback:"CallBack",
    depositor:"Depositor",
    depositorwalletaddress:"Depositor Wallet Address",
    issuer:"User Real Name",
    issuerwalletaddress:"User Address",
    inprogress:"In Progress",
    coinstransfered:"Transferred",
    expired:"Expired",
    callbackfailed:"Callback failed",
    callbackrequestsubmitted:"Callback sent",
    confirm:"Confirm",
    cancel:"Cancel",
    confirmorderdelection:"Confirm delete order",
    obtain:"Obtain",
    oldpassword:"Old Password",
    newpassword:"New Password",
    confirmnewpassword:"Confirm New Password",
    newpasswordincorrectformat:"Password Format Incorrect",
    newsubpasswordincorrectformat:"Payment Password Format Incorrect",
    oldpasswordnotmatchnewpassword:"Password Not Match",
    oldsubpasswordnotmatchnewsubpassword:"Payment Password Not Match",
    resetpasswordsuccess:"Password reset success",
    resetsubpasswordsuccess:"Payment password reset success",
    resetpasswordfailed:"Payment reset fail. Please retry.",
    oldsubpassword:"Old Payment Password",
    newsubpassword:"New Payment Password",
    confirmnewsubpassword:"Confirm New Payment Password",
    settingupdatesuccess:"Setting save success",
    settingupdatefail:"Setting save fail",
    app_name_exists:"App name exists",
    ga_verification:"GA Verification",
    gaTitle:"GA Verification",
    input_instruction:"1. Scan or input the code:",
    input_instruction_2:"2. Input 6-digit code:",
    bind_now:"Bind Now",
    input_6_digit_code:"Please input 6-digit code",
    already_bind:"Already bind. Please contact customer service if you need to change",
    ga_verification_failed:"GA Verification failed",
    depositrebate:"Deposit Rebate",
    buyorderrebate:"Buy Order Rebate",
    takerdeductusdt:"Taker Deduct USDT",
    makerdeductusdt:"Maker Deduct USDT",
    merchantmarkupdeduct:"Merchant Markup Deduct",
    deposit_offer_percent:"Deposit Offer",
    deposit_offer_dla:"Deposit Offer Limit",
    deposit_offer_dln:"Deposit Offer Times",
    withdraw_offer_percent:"Withdraw Offer",
    withdraw_offer_dla:"Withdraw Offer Limit",
    withdraw_offer_dln:"Withdraw Offer Times",
    pls_deposit_offer_percent:"Please input deposit offer",
    pls_deposit_offer_dla:"Please input deposit offer limit",
    pls_deposit_offer_dln:"Please input deposit offer times",
    pls_withdraw_offer_percent:"Please input withdraw offer",
    pls_withdraw_offer_dla:"Please input withdraw offer limit",
    pls_withdraw_offer_dln:"Please input withdraw offer times",
    withdraw_deposit_offer_success:"Withdraw Deposit Offer Success",
    withdraw_deposit_offer_fail:"Withdraw Deposit Offer Fail",
    discount:"Discount",
    offerAdd:"Offer Add",
    offerDeduct:"Offer Deduct",
    withdraw_deposit_settings:"Withdraw Deposit Settings",
    deposit_offer_percent_range:"Out of range",
    deposit_min:'User deposit min amount',
    withdraw_min:'User withdraw min amount',
}

export default en;