import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMemo } from 'react';
import http from '../lib/http'
import useSWR from 'swr'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next'
import Store from 'store'

import Header from "../components/header"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../App.scss"

function createData(id,amount,total_amount,fee,subject,time) {
    return { id, amount, total_amount, fee, subject,time };
}

const Bindings = () => {
    const { t } = useTranslation();
    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/binding-list`;
    const [data,setData] = useState([]);
    const { search } = useLocation();
    const [address_query,setAddressQuery] = useState('');

    const [startDate, setStartDate] = useState(new Date()); 
    const [endDate, setEndDate] = useState(new Date()); 
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async()=>{
        var params = {
            "wallet_address":address_query
        };
        console.log(params);
        let data = await http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.data && response.data.lists && response.data.lists.data){
                setRows(response.data.lists.data);
            }
        });  
    }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-2 vh-100 overflow-auto">
                <h4 className='text-dark-blue'>{t('userbinding')}</h4>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="d-flex gap-2 align-items-center">
                        <span style={{minWidth:80}}>{t('walletaddress')}</span>
                        <input className="w-100 p-2" value={address_query} name="address_query" placeholder={t('walletaddress')} onChange={(e)=>setAddressQuery(e.target.value)}/>
                        <button style={{minWidth:100}} className="bg-dark-blue text-white p-2 rounded-2 border-0 fs-6" onClick={fetchData}>{t('inquiry')}</button>
                    </div>
                    <div className="h-100 overflow-auto">
                        <TableContainer component={Paper}>
                            <Table className="w-100" aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">{t('nickname')}</TableCell>
                                    <TableCell align="right">{t('username')}</TableCell>
                                    <TableCell align="right">{t('walletaddress')}</TableCell>
                                    <TableCell align="right">{t('realname')}</TableCell>
                                    <TableCell align="right">V币{t('quantity')}</TableCell>
                                    <TableCell align="right">{t('state')}</TableCell>
                                    <TableCell align="right">{t('updatetime')}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="right">{row.user.nickname}</TableCell>
                                    <TableCell align="right">{row.user.name}</TableCell>
                                    <TableCell align="right">{row.user.wallet_address}</TableCell>
                                    <TableCell align="right">{row.user.finished_realname_verify?row.user.finished_realname_verify.id_name:""}</TableCell>
                                    <TableCell align="right">{parseFloat(row.user.usdt)+parseFloat(row.user.available_usdt)}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                    <TableCell align="right">{row.updated_at}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bindings