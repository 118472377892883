
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Header from '../components/header'
import './home.scss'

const Home = () => {
    const{t} = useTranslation()
    const history = useHistory()
    
    const goPage = useCallback((url) => {
        history.push(url)
    } ,[history])

    return (
        <div className="Home d-flex">
            <Header isHome></Header>
            <ul>
                <li className="list1" onClick={goPage.bind(this, '/invite')}>
                    <h6>{t('list1Title')}</h6>
                    <p>{t('list1Detail')}</p>
                </li>
                <li className="list2" onClick={goPage.bind(this, '/proxy')}>
                    <h6>{t('list2Title')}</h6>
                    <p>{t('list2Detail')}</p>
                </li>
                <li className="list3" onClick={goPage.bind(this, '/task')}>
                    <h6>{t('list3Title')}</h6>
                    <p>{t('list3Detail')}</p>
                </li>
                <li className="list4" onClick={goPage.bind(this, '/settings')}>
                    <h6>{t('list4Title')}</h6>
                    <p>{t('list4Detail')}</p>
                </li>
            </ul>
        </div>
    )
}

export default Home