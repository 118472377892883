
import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import moment from 'moment'
import './invite.scss'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Store from 'store'

const Invite = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(1)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(1)
    const {data:listData, error: err}  = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/user/agent-friend?limit=10&page=${page}`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})
    const {data:totalData } = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/user/dashboard-data`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})

    const totalInvite = useMemo(() => {
        return (totalData && totalData.status === 0 && totalData.data.total) || 0
    }, [totalData])

    const loadMore = useCallback(() => {
        setPage(parseInt(page + 1));
    }, [page])

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData && listData.status === 0) {
            const { data: {data, total_page} } = listData
            setTotal(total_page);
            setList(v => [...v, ...data]);
        }
        // if(listData!==undefined){
        //     alert(t('notinwhitelist'));
        //     Store.remove('user')
        //     window.location.href = '/'
        // }
        if (listData || err) {
            Toast.clear()
        }

        

    }, [listData, err])


    return (
        <div className="Invite d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-2 vh-100 overflow-auto">
                <h4 className='text-dark-blue'>{t('list1Title')} - {t('total')} {totalInvite}</h4>
                <div className='bg-white'>
                    <TableContainer component={Paper}>
                        <Table className="w-100" aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell className="text-center">{t('account')}</TableCell>
                                <TableCell className="text-center">{t('num')}</TableCell>
                                <TableCell className="text-center">{t('invite_id')}</TableCell>
                                <TableCell className="text-center">{t('type')}</TableCell>
                                <TableCell className="text-center">{t('in_time')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {list.map((item) => (
                                <TableRow
                                key={item.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{item.id}</TableCell>
                                    <TableCell component="th" scope="row">{item.name}</TableCell>
                                    <TableCell component="th" scope="row">{item.usdt}</TableCell>
                                    <TableCell component="th" scope="row">{item.parent_id}</TableCell>
                                    <TableCell className="text-center">{item.type === 2 ? t('boss') : t('agent') }</TableCell>
                                    <TableCell align="right">{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                {/* <InfiniteScroll loadMore={loadMore} hasMore={total-page > 0} /> */}
            </div>
        </div>
    )
}

export default Invite