import {useEffect, useState } from 'react'
import Header from '../components/header'
import http from '../lib/http'
import './withdraw.scss'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Toast } from 'antd-mobile/2x'
import { useTranslation } from 'react-i18next'
import Store from 'store'

const Withdraw = () => {
    const { t } = useTranslation();

    const url = `${process.env.REACT_APP_API_URL}/agentbackend/user/withdraw`;
    const me_url =`${process.env.REACT_APP_API_URL}/agentbackend/user/me`;

    const [me, setMyInfo] = useState({})
    const [account, setAccount] = useState('')
    const [amount, setAmount] = useState('')
    const [fee, setFee] = useState(0)
    const [sub_password, setSubPassword] = useState('')
    const [ga_password, setGaPassword] = useState('')
    const [withdraw_type, setWithdrawType] = useState('ERC20') //ERC20 or RMB
    const [type, setType] = useState('TRC') //TRC or ERC

    const [bank_name, setBankName] = useState('')
    const [bank_account, setBankAccount] = useState('')
    const [bank_holder, setBankHolder] = useState('')

    //submit whitelist function
    const submitTransfer = async()=>{
        if(withdraw_type === 'ERC20'){
            if(!account){
                alert(t('pleaseinputwalletaddress'))
                return ;
            }
        }
        if(withdraw_type === 'RMB'){
            if(!bank_name){
                alert(t('pleaseinputbranchname'))
                return ;
            }
            if(!bank_account){
                alert(t('pleaseinputaccountnumber'))
                return ;
            }
            if(!bank_holder){
                alert(t('pleaseinputname'))
                return ;
            }
        }


        if(!amount || amount<=0){
            alert(t('pleaseinputtransferamount'))
            return ;
        }
        if(!sub_password){
            alert(t('pleaseinputsubpassword'))
            return ;
        }
        if(!ga_password){
            alert(t('pleaseinputgapassword'))
            return ;
        }
        
        
        var params ;
        if(withdraw_type === 'ERC20' || withdraw_type === 'TRC20'){
            params = {
                address: account,
                amount: amount,
                sub_password: sub_password,
                ga_code: ga_password,
                withdraw_type:withdraw_type,
                network:type,
            };
        }else{
            params = {
                bank: bank_name,
                address: bank_account,
                name: bank_holder,
                amount: amount,
                sub_password: sub_password,
                ga_code: ga_password,
                network:withdraw_type,
            };
        }
        
        console.log(params);

        await http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.status == 0 && response.message == "提币成功"){
                Toast.show({
                    icon: 'success',
                    content: t('withdrawsuccess'),
                    maskClickable: true
                })
                reset();
                return;
            }
            if(response.status == 1 && response.message == "Ga Code Error"){
                Toast.show({
                    icon: 'fail',
                    content: t('gapasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            if(response.status == 101 && response.message == "incorrect sub password"){
                Toast.show({
                    icon: 'fail',
                    content: t('subpasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            
            Toast.show({
                icon: 'fail',
                content: t('withdrawfailed'),
                maskClickable: true
            })
        });  
    }

    function reset(){
        setAccount('')
        setAmount('')
        setFee(me.config.transaction_withdraw_fee)
        setSubPassword('')
        setGaPassword('')
        setType('TRC')
        setBankName('')
        setBankAccount('')
        setBankHolder('')
    }

    function selectAllAmount(_type){
        if(_type === 'ERC20'){
            setAmount(me.available_usdt); //set max ERC20 / TRC20 amount
        }else{
            setAmount(me.available_usdt); //set max RMB amount
        }
    }

    useEffect(async () => {
        await http({ url: me_url, method: 'post'}).then(response=>{
            console.log("auth me",response);
            if(response.status == 0){
                setMyInfo(response.data);
                if(response.data.config){
                    setFee(response.data.config.transaction_withdraw_fee)
                }
            }
            
        });
    },[])
    

    return (
        <div className="d-flex">
            <Header></Header>
            <div style={{maxWidth:800}} className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('balance')}{t('withdrawal')}</h4>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column gap-4 bg-white p-4">
                        <div>
                            <label style={{width:120}}><span className="text-danger">* </span>{t('withdrawal')}</label>
                            <Select style={{height:40}} id="withdraw_type" value={withdraw_type} onChange={(e)=>{setWithdrawType(e.target.value);reset();}} >
                                <MenuItem value={'ERC20'}>USDT</MenuItem>
                                <MenuItem value={'RMB'}>{t('rmb')}</MenuItem>
                            </Select>
                        </div>

                        {withdraw_type === 'ERC20' && <div>
                            <label style={{width:120}}><span className="text-danger">* </span>{t('type')}</label>
                            <FormControl>
                                <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="TRC" name="radio-buttons-group" onChange={(e)=>setType(e.target.value)}>
                                    <FormControlLabel value="TRC" control={<Radio />} label="USDT-TRC20" />
                                    <FormControlLabel value="ERC" control={<Radio />} label="USDT-ERC20" />
                                </RadioGroup>
                            </FormControl>
                        </div>}
                        {withdraw_type === 'ERC20' && <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>USDT{t('address')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="account" name="account" value={account} placeholder={t('pleaseinputwalletaddress')} onChange={(e)=>setAccount(e.target.value)}/>
                        </div>}

                        {withdraw_type === 'RMB' && <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('branchname')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="bank_name" name="bank_name" value={bank_name} placeholder={t('pleaseinputbranchname')} onChange={(e)=>setBankName(e.target.value)}/>
                        </div>}
                        {withdraw_type === 'RMB' && <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('name')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="bank_holder" name="bank_holder" value={bank_holder} placeholder={t('pleaseinputname')} onChange={(e)=>setBankHolder(e.target.value)}/>
                        </div>}
                        {withdraw_type === 'RMB' && <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('accountnumber')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="bank_account" name="bank_account" value={bank_account} placeholder={t('pleaseinputaccountnumber')} onChange={(e)=>setBankAccount(e.target.value)}/>
                        </div>}

                        <div className='d-flex flex-column gap-2'>
                            <div className="d-flex flex-row gap-2 align-items-center">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{withdraw_type==='ERC20'?t('quantitywithdraw'):t('cashwithdraw')}</label>
                                <input style={{maxWidth:500}} className="w-100 p-2" id="amount" name="amount" type="number" value={amount} placeholder="0" onChange={(e)=>setAmount(e.target.value)}/>
                            </div>
                            {withdraw_type==='ERC20' && <div style={{fontSize:12,maxWidth:625}} className='d-flex justify-content-between'>
                                <div className='d-flex gap-2'>
                                    <label style={{minWidth:120}}></label>
                                    <label>{t('amountcanwithdraw')}:{me.available_usdt>0?me.available_usdt:0}</label>
                                    <span role="button" className='text-primary border-bottom border-primary' onClick={()=>selectAllAmount('ERC20')}>{t('withdrawall')}</span>
                                </div> 
                                <span>{t('todayexchangerate')} {me.config?me.config.withdraw_rate:0}</span>
                            </div>}
                            {withdraw_type==='RMB' && <div style={{fontSize:12,maxWidth:625}} className='d-flex justify-content-between'>
                                <div className='d-flex gap-2'>
                                    <label style={{minWidth:120}}></label>
                                    <label>{t('amountcanwithdraw')}:{me.available_usdt>0?me.available_usdt:0}</label>
                                    <span role="button" className='text-primary border-bottom border-primary' onClick={()=>selectAllAmount('RMB')}>{t('withdrawall')}</span>
                                </div> 
                                <span>{t('minwithdraw')} {me.config?me.config.transaction_withdraw_min_limit:0}</span>
                            </div>}
                        </div>
                        
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('fee')} ({me.config?me.config.transaction_withdraw_fee:0}%)</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="fee" name="fee" value={fee} placeholder="0" onChange={(e)=>setFee(e.target.value)} disabled/>
                        </div>
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('subpassword')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="sub_password" name="sub_password" value={sub_password} placeholder={t('pleaseinputsubpassword')} onChange={(e)=>setSubPassword(e.target.value)}/>
                        </div>
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('gapassword')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="ga_password" name="ga_password" value={ga_password} placeholder={t('pleaseinputgapassword')} onChange={(e)=>setGaPassword(e.target.value)}/>
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitTransfer}>{t('submit')}</button>
                            <button className="bg-white border border-1 text-black px-5 py-2 rounded-1" onClick={reset}>{t('reset')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdraw 