import { InfiniteScroll, Toast } from 'antd-mobile/2x';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import http from '../lib/http'
import useSWR from 'swr';
import Header from "../components/header"
import './task.scss'
import 'reactjs-popup/dist/index.css';
import Store from 'store'


const GAVerification = () => {
    const {t} = useTranslation()

    const [secret, setSecret] = useState("")
    const [qr, setQR] = useState("")
    const [ga_code, setGA] = useState("")
    const {data:ga, error:err}  = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/user/ga-verification`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})
    const [state, setState] = useState(false)
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (ga || err) {
            Toast.clear()
        }
        
        if (ga && ga.status === 0) {
            console.log(ga.data)
            setSecret(ga.data.secret)
            setQR(ga.data.qr)
        }else if(ga && ga.status === 101){
            //alert(t('already_bind'));
            setState(true);
            //console.log(state)
        }
    }, [ga, err])

    const submitGA = () => {
        http({ url: process.env.REACT_APP_API_URL+'/agentbackend/user/verify-ga', method: "POST", data: { "secret":secret,"ga_code":ga_code,} })
        .then(res => {
            if(res.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(res.data.result){
                Toast.show({
                    icon: 'success',
                    content: t('settingupdatesuccess'),
                    maskClickable: true
                })
                window.location.href = '/dashboard'
                return;
            }else{
                Toast.show({
                    icon: 'fail',
                    content: t("ga_verification_failed"),
                    maskClickable: true
                })
                return;
            }
        })
        .catch(err => {
            console.log("err",err);
        })
    }

    const handleChange = event => {
        if(event.target.id=='ga_code')
            setGA(event.target.value);
    };

    return (
        <div className="task d-flex">
            <style></style>
            <Header>GA</Header>
            <div v-else className="w-100 p-4 d-flex flex-column gap-2 vh-100 overflow-auto position-relative">
                
                <h4 className='text-dark-blue'>{t('gaTitle')}</h4>
                {state === true && <div className="loading">{t('already_bind')}</div>}
                {state === false && <div className="loading">
                    <div>{t('input_instruction')}{secret}</div><br/>
                    <div><img width="200" src={qr} /></div><br/>
                    <div>
                        <span>{t('input_instruction_2')}</span><br/>
                        <input className="w-75" id="ga_code" name="ga_code" value={ga_code} placeholder={t('input_6_digit_code')} onChange={handleChange}/><br/>
                        <br/><button onClick={submitGA}>{t('bind_now')}</button>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default GAVerification;