import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'


const WithdrawDepositOffer = () => {
    const { t } = useTranslation();
    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/save-offer`;

    const [deposit_offer_percent,setDepositOfferPercent] = useState('')
    const [deposit_offer_dla, setDepositOfferDLA] = useState('')
    const [deposit_offer_dln, setDepositOfferDLN] = useState('')
    const [withdraw_offer_percent, setWithdrawOfferPercent] = useState('')
    const [withdraw_offer_dla, setWithdrawOfferDLA] = useState('')
    const [withdraw_offer_dln, setWithdrawOfferDLN] = useState('')
    const [withdraw_min, setWithdrawMin] = useState('')
    const [deposit_min, setDepositMin] = useState('')

    useEffect(() => {
        http({ url: `${process.env.REACT_APP_API_URL}/agentbackend/user/agent/get-offer`, method: "POST" })
        .then(res => {
            if(res.data.length > 0){
                res.data.forEach(element => {
                    if(element.name == "deposit_offer"){
                        var v = JSON.parse(element.value);
                        setDepositOfferPercent(v.percent)
                        setDepositOfferDLA(v.daily_limit_amount)
                        setDepositOfferDLN(v.daily_limit_no)
                    }else if(element.name == "withdraw_offer"){
                        var v = JSON.parse(element.value);
                        setWithdrawOfferPercent(v.percent)
                        setWithdrawOfferDLA(v.daily_limit_amount)
                        setWithdrawOfferDLN(v.daily_limit_no)
                    }else if(element.name == "API_WITHDRAW_MIN"){
                        setWithdrawMin(JSON.parse(element.value))
                        
                    }else{
                        setDepositMin(JSON.parse(element.value))
                    }
                });
            }else{
                setDepositOfferPercent(0)
                setDepositOfferDLA(0)
                setDepositOfferDLN(0)
                setWithdrawOfferPercent(0)
                setWithdrawOfferDLA(0)
                setWithdrawOfferDLN(0)
                setDepositMin(1)
                setWithdrawMin(1)
            }         
        })
        .catch(err => {
            console.log("err",err);
        })
    },[])

    async function submitNewRules(){
        if(deposit_offer_percent > 1 || deposit_offer_percent < 0){
            Toast.show({
                icon: 'fail',
                content: t('deposit_offer_percent_range'),
                maskClickable: true
            })
            return;
        }
        if(withdraw_offer_percent > 1 || withdraw_offer_percent < 0){
            Toast.show({
                icon: 'fail',
                content: t('deposit_offer_percent_range'),
                maskClickable: true
            })
            return;
        }
        let params={
            "name":"deposit_offer",
            "value":{
                "deposit_offer":{
                    "percent":deposit_offer_percent,
                    "daily_limit_amount":deposit_offer_dla,
                    "daily_limit_no":deposit_offer_dln
                },
                "withdraw_offer":{
                    "percent":withdraw_offer_percent,
                    "daily_limit_amount":withdraw_offer_dla,
                    "daily_limit_no":withdraw_offer_dln
                },
                "API_DEPOSIT_MIN":deposit_min,
                "API_WITHDRAW_MIN":withdraw_min
            }
        }

        let data = await http({ url: url, method: 'post',params:params}).then((response)=>{ 
            if(response.status == 0){
                Toast.show({
                    icon: 'success',
                    content: t('withdraw_deposit_offer_success'),
                    maskClickable: true
                })
                return;
            }
            Toast.show({
                icon: 'fail',
                content: t('withdraw_deposit_offer_fail'),
                maskClickable: true
            })
        });  
    }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('withdraw_deposit_settings')}</h4>
                <h5 className="text-dark-blue">{t('withdraw_deposit_settings')}</h5>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('deposit_min')}</label>
                        <input style={{width:200}} id="deposit_min" name="deposit_min" type="text" min="1" value={deposit_min} placeholder={t('deposit_min')} onChange={(e) => setDepositMin(e.target.value)}/>
                        <span>1-1000000</span>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('withdraw_min')}</label>
                        <input style={{width:200}} id="withdraw_min" name="withdraw_min" min="1" type="text" value={withdraw_min} placeholder={t('withdraw_min')} onChange={(e) => setWithdrawMin(e.target.value)} />
                        <span>1-1000000</span>
                    </div>
                </div>
                <h5 className="text-dark-blue">{t('withdraw_deposit_offer')}</h5>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('deposit_offer_percent')}</label>
                        <input style={{width:200}} id="deposit_offer_percent" name="deposit_offer_percent" type="text" min="0" max="1" value={deposit_offer_percent} placeholder={t('pls_deposit_offer_percent')} onChange={(e) => setDepositOfferPercent(e.target.value)}/>
                        <span>% 0 ~ 0.99</span>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('deposit_offer_dla')}</label>
                        <input style={{width:200}} id="deposit_offer_dla" name="deposit_offer_dla" type="number" value={deposit_offer_dla} placeholder={t('pls_deposit_offer_dla')} disabled="disabled"/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('deposit_offer_dln')}</label>
                        <input style={{width:200}} id="deposit_offer_dln" name="deposit_offer_dln" type="number" value={deposit_offer_dln} placeholder={t('pls_deposit_offer_dln')} disabled="disabled"/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('withdraw_offer_percent')}</label>
                        <input style={{width:200}} id="withdraw_offer_percent" name="withdraw_offer_percent" type="number" value={withdraw_offer_percent} placeholder={t('pls_withdraw_offer_percent')} onChange={(e) => setWithdrawOfferPercent(e.target.value)}/>
                        <span>% 0 ~ 0.99</span>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('withdraw_offer_dla')}</label>
                        <input style={{width:200}} id="withdraw_offer_dla" name="withdraw_offer_dla" type="number" value={withdraw_offer_dla} placeholder={t('pls_withdraw_offer_dla')} disabled="disabled"/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('withdraw_offer_dln')}</label>
                        <input style={{width:200}} id="withdraw_offer_dln" name="withdraw_offer_dln" type="number" value={withdraw_offer_dln} placeholder={t('pls_withdraw_offer_dln')} disabled="disabled"/>
                    </div>
                    <div className="">
                        <button className="token-generate bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitNewRules}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithdrawDepositOffer 