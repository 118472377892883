import {Toast } from 'antd-mobile/2x'
import {useEffect, useState,useMemo } from 'react'
import Header from '../components/header'
import http from '../lib/http'
import './transfer.scss'
import { useTranslation } from 'react-i18next'
import payment_alipay from '../assets/payment_alipay.png'
import payment_card from '../assets/payment_card.png'
import payment_drmb from '../assets/payment_drmb.png'
import payment_qq from '../assets/payment_qq.png'
import payment_unionpay from '../assets/payment_unionpay.png'
import payment_wechat from '../assets/payment_wechat.png'
import classNames from 'classnames';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import bignumber from 'bignumber.js';
import { fontSize } from '@mui/system'

import {address} from '../assets/addr.js';
import {b_n} from '../assets/bank_name.js';
import upload from '../assets/upload_file_btn.png'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import icon_alipay from '../assets/icon_square_alipay.webp'
import icon_card from '../assets/icon_square_card.png'
import icon_drmb from '../assets/icon_square_drmb.webp'
import icon_qq from '../assets/icon_square_qq.webp'
import icon_union from '../assets/icon_square_union_pay.webp'
import icon_wechat from '../assets/icon_square_wechat.webp'


const Paytype = () => {
    const { t } = useTranslation();

    const base_url = `${process.env.REACT_APP_API_URL}` ;
    const url = `${process.env.REACT_APP_API_URL}/webapp/user/set-paysettings-alone`;
    const unbind_url = `${process.env.REACT_APP_API_URL}/webapp/user/unbind-paysettings`;
    const card_unbind_url = `${process.env.REACT_APP_API_URL}/webapp/user/remove-paysettings`;
    const me_url =`${process.env.REACT_APP_API_URL}/agentbackend/user/me`;
    const upload_media_url=`${process.env.REACT_APP_API_URL}/media/upload`;
    const update_avatar_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/set-avatar`;

    const [me, setMyInfo] = useState({})
    const [goal, setGoal] = useState('')
    const [discount, setDiscount] = useState(0)
    const [display_discount, setDisplayDiscount] = useState(0)
    const [tab, setTab] = useState(2)
    const [price_title, setPriceTitle] = useState('')
    const [special_min, setSpecial_min] = useState(0)
    const [special_max, setSpecial_max] = useState(2)
    const [min_usdt_limit, setMin_usdt_limit] = useState(0)
    const [max_usdt_limit, setMax_usdt_limit] = useState(0)
    const [can_card, set_can_card] = useState(0)
    const [can_alipay, set_can_alipay] = useState(0)
    const [can_wechat, set_can_wechat] = useState(0)
    const [can_drmb, set_can_drmb] = useState(0)
    const [can_qq, set_can_qq] = useState(0)
    const [can_unionpay, set_can_unionpay] = useState(0)
    const [unitprice, setUnitprice] = useState(1)
    const [slider_option, setSlider_option] = useState({})
    const [split, setSplit] = useState(0)
    const [subpassword, setSubPassword] = useState('')

    const [pay_drmb_flag, setDrmbflag] = useState(0)
    const [pay_alipay_flag, setAlipayflag] = useState(0)
    const [pay_wechat_flag, setWechatflag] = useState(0)
    const [pay_card_flag, setCardflag] = useState(0)
    const [pay_qq_pay_flag, setQqpayflag] = useState(0)
    const [pay_union_pay_flag, setUnionpayflag] = useState(0)

    const [target_card, setTargetCard] = useState(''); //if more than 1 card can be select , pick 1

    const [availableUsdt, setAvailableUsdt] = useState(0)
    const [myUsdt, setMyUsdt] = useState(0)

    const [pay_type, setPayType] = useState('alipay')
    const [payee_name, setPayeeName] = useState('')
    const [payee_account, setPayeeAccount] = useState('')
    const [payee_qrcode, setPayeeQrcode] = useState('')
    const [payee_address, setPayeeAddress] = useState('')
    const [payee_id, setPayeeId] = useState('')
    const [payee_orgname, setPayeeOrgname] = useState('')
    const [remark, setRemark] = useState('')
    const [in_use, setInUse] = useState(3)
    const [qrcode_url, setQrcodeUrl] = useState(upload);
    const [payment_status, setinuseStatus] = useState([t('approving'),t('bind'),t('reject'),t('nobind')])
    const [payment_option_select_id, setPaymentOptionSelectId] = useState(-1)

    const [bank_array, setBank] = useState([]);
    const [address_array, setAddress] = useState([]);
    const [district_array, setDistrict] = useState([]);
    const [area_array, setArea] = useState([]);

    const [selected_city, setSelectedCity] = useState('');
    const [selected_district, setSelectedDistrict] = useState('');
    const [selected_area, setSelectedArea] = useState('');

    //submit whitelist function
    const sendPayBinding = async()=>{
        if (!payee_name) {
            alert(t('plzname'));
            return;
        }
        var re=/^[\u4E00-\u9FA5]+$/;
        if(!re.test(payee_name)){
            alert(t('plzChinese'));
            return;
        }

        if(selected_city == '' || selected_district == ''){ //|| selected_area.length == '' 
            alert(t('plzAddress'));
            return;
        }

        // if (!payee_qrcode) {
        //     alert(t('plzQc'));
        //     return;
        // }

        // if(pay_type == 'union_pay' && !payee_orgname){
        //     alert(t('plzorgname'));
        //     return;
        // }

        if((pay_type == 'wechat' || pay_type == 'wechat_m' || pay_type == 'alipay_qr_p' || pay_type == 'alipay_qr_m' ) && !payee_qrcode){ //|| pay_type == 'qq_pay'
            alert(t('plzQr'));
            return;
        }

        if(pay_type == 'alipay' || pay_type == 'card' || pay_type == 'drmb'){
            if (!payee_account) {
                alert(t('plzaccount'));
                return;
            }
    
            var re= /^[a-zA-Z0-9]+$/; //English and digit
            if (!re.test(payee_account)) {
                alert(t('accountwrongtype'));
                return;
            }
        }

        if(pay_type.includes("wechat") || pay_type.includes("alipay")){
            if (!remark) {
                alert(t('plzremark'));
                return;
            }
        }

        let params = {
            'type':pay_type,
            'payee_name':payee_name,
            'payee_account':payee_account,
            'payee_qrcode':payee_qrcode,
            'payee_address':selected_city + "," + selected_district + "," + selected_area,
            'payee_orgname':payee_orgname,
            'id':payee_id,
            'remark':remark
        };
        console.log(params);

        await http({ url: url, method: 'post',params:params}).then(response=>{
            console.log(response);
            if(response.status == 0){
                Toast.show({
                    icon: 'success',
                    content: t('settingsuccess'),
                    maskClickable: true
                })
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                return;
            }else{
                alert('Something went wrong');
            }
        });  
    }

    const sendPayUnBinding = async()=>{
        let params={
            'type':pay_type,
            'id':payment_option_select_id
        };

        if(pay_type == 'card'){
            var url = card_unbind_url;
        }else{
            var url = unbind_url;
        }
        await http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.status == 0){
                if(response.message=='This payment method is being used by an order, please remove the order first' || response.message=='This payment method is being used by a task, please remove the task first'){
                    Toast.show({
                        icon: 'fail',
                        content: t('popupContentExistingTask'),
                        maskClickable: true
                    })
                    return ;
                }
                Toast.show({
                    icon: 'success',
                    content: t('settingsuccess'),
                    maskClickable: true
                })
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }else{
                alert('Something went wrong');
            }
        });  
    }

    function reset(){
        setGoal('');
        setSplit(0);
    }

    function isCard(){
        if(pay_type == 'card'){
            var in_use = me.payment_method && me.payment_method.filter((p)=> p.in_use == 0 && p.type == 'card');
            if(in_use.length > 0){
                return <MenuItem value={in_use[0].id}>添加收款账户</MenuItem>
            }else{
                return <MenuItem value='-1'>添加收款账户</MenuItem>
            }
        }else{
            return <MenuItem value='-1'>添加收款账户</MenuItem>
        }
    }

    function inuseStatus(_id){
        //use if to search payment data
        if(_id == -1)return 3 ;
        var payment = me.payment_method.filter((p)=> p.id == _id);
        if(payment.length <= 0)return 3 ;

        var _pay_type = payment[0].type ;
        var _in_use = payment[0].in_use ;
        var _remark = payment[0].remark ;
        var _payee_account = payment[0].payee_account ;
        var _payee_qrcode = payment[0].payee_qrcode ;

        var status = _in_use ;

        if(_in_use == 0 && _remark != ''){
            if(_pay_type == 'alipay'){
                if(_payee_account == ''){
                    status = 3 ;
                }
            }
            if(_pay_type == 'wechat' || _pay_type == 'wechat_m' || _pay_type == 'alipay_qr_p' || _pay_type == 'alipay_qr_m'){
                if(_payee_qrcode == ''){
                    status = 3 ;
                }
            }
        }
        if(_pay_type == 'card' && _in_use == 0){
            status = 3 ;
        }
        if(_pay_type == 'drmb' && _in_use == 0){
            status = 3 ;
        }
       
        return status
    }

    function changePaymentId(_e){
        setPaymentOptionSelectId(_e);
        var p = me.payment_method.filter((p)=> p.id == _e);
        if(p.length > 0){
            setPayeeOrgname(p[0].payee_orgname);
            setPayeeName(p[0].payee_name);
            setPayeeAccount(p[0].payee_account);
            setPayeeQrcode(p[0].payee_qrcode);
            setQrcodeUrl(checkBaseUrl(p[0].payee_qrcode));
            setPayeeId(p[0].id);
            setRemark(p[0].remark);
            setInUse(p[0].in_use);
            //read payee address , load to dropdown menu
            if(p[0].payee_address.split(',').length > 0 ){
                const add = p[0].payee_address.split(',') ;
                setSelectedCity(add[0]);
                var c = address_array.filter((city)=>city.name == add[0]);
                setDistrict(c[0].children) ;
                setSelectedDistrict(add[1]);
                // var d = c[0].children.filter((district)=>district.name == add[1]);
                // setArea(d[0].children) ;
                // setSelectedArea(add[2]);
            }else{
                setSelectedCity('');
                setSelectedDistrict('');
                setSelectedArea('');
    
                setDistrict([]) ;
                setArea([]) ;
            }
        }else{
            setPayeeOrgname('');
            setPayeeName('');
            setPayeeAccount('');
            setPayeeQrcode('');
            setQrcodeUrl(upload);
            setPayeeId('');
            setRemark('');
            setInUse(3);
            setSelectedCity('');
            setSelectedDistrict('');
            setSelectedArea('');

            setDistrict([]) ;
            setArea([]) ;
        }
    }

    function changePayType(_e){
        setPayType(_e);
        getPaymentMethod(_e);
    }

    function changeCity(_e){
        console.log('changeCity',_e);
        setSelectedCity(_e);
        var city = address_array.filter((city)=>city.name == _e);
        console.log('changeCity',city[0]);
        setDistrict(city[0].children) ;
        setArea([]);
        setSelectedDistrict('');
        setSelectedArea('');
    }

    function changeDistrict(_e){
        console.log('changeDistrict',_e);
        setSelectedDistrict(_e);
        var district = district_array.filter((district)=>district.name == _e);
        console.log('changeDistrict',district);
        setArea(district[0].children) ;
        setSelectedArea('');
    }

    function getPaymentMethod(pay_type='alipay'){
        var p = me.payment_method.filter((p)=> p.type == pay_type);
        if(p.length > 0){
            changePaymentId(p[0].id);
        }else{
            changePaymentId(-1);
        }
    }

    function checkBaseUrl(_url){
        if(!_url)return _url;
        if(_url.includes(base_url) == false){
            return base_url + _url
        }
        return _url
    }

    async function updateQrCode(_code) {
        const formData = new FormData();
        formData.append('file', _code.target.files[0]);
      
        try {
          const response = await fetch(upload_media_url, {
            method: 'POST',
            body: formData,
          });
      
          if (!response.ok) {
            throw new Error('Failed to upload image');
          }
      
          const data = await response.json();
          console.log('upload_media_url', data);
          setPayeeQrcode(data.data.url);
          setQrcodeUrl(checkBaseUrl(data.data.url));
      
        } catch (error) {
          console.error('Error uploading image:', error.message);
        }
      }

    useEffect(async() => {
        var a = address;
        a.map((city)=>{
            city.text=city.name;
            city.value=String(city.name);
            if(city.children != undefined){
                city.children.map((district)=>{
                    district.text=district.name;
                    district.value=String(district.name);
                    if(district.children != undefined){
                        district.children.map((area)=>{
                            area.text=area.name;
                            area.value=String(area.name);
                        });
                    }
                });
            }
        });
        setAddress(a);
        setBank(b_n.map((v)=>{return {'value':v,'text':v}}));

        if(address_array.length > 0){
            if(JSON.stringify(me) == "{}"){
                await http({ url: me_url, method: 'post'}).then(response=>{
                    if(response.status == 0){
                        setMyInfo(response.data);
                    }
                });
            }
        }
    },[address_array])

    useEffect(() => {
        if(JSON.stringify(me) != "{}"){
            getPaymentMethod();
        }
    },[me])
    

    return (
        <div className="d-flex">
            <Header></Header>
            <div style={{maxWidth:800}} className="w-100 p-4 d-flex flex-column gap-4 vh-100 overflow-scroll">
                <h4 className="text-dark-blue text-center">{t('paytype')}</h4>
                <div className="text-center d-flex flex-column gap-2 align-items-center">
                    <Select style={{height:40}} id="pay_type" value={pay_type} onChange={(e)=>{changePayType(e.target.value);}}>
                        <MenuItem value='alipay'><img style={{width:30,paddingRight:8}} src={icon_alipay} alt="" />支付宝帐号</MenuItem>
                        <MenuItem value='alipay_qr_p'><img style={{width:30,paddingRight:8}} src={icon_alipay} alt="" />支付宝个人收款码</MenuItem>
                        <MenuItem value='alipay_qr_m'><img style={{width:30,paddingRight:8}} src={icon_alipay} alt="" />支付宝商家收款码</MenuItem>
                        <MenuItem value='wechat'><img style={{width:30,paddingRight:8}} src={icon_wechat} alt="" />微信个人收款码</MenuItem>
                        <MenuItem value='wechat_m'><img style={{width:30,paddingRight:8}} src={icon_wechat} alt="" />微信商家收款码</MenuItem>
                        <MenuItem value='card'><img style={{width:30,paddingRight:8}} src={icon_card} alt="" />银联卡转帐</MenuItem>
                        <MenuItem value='drmb'><img style={{width:30,paddingRight:8}} src={icon_drmb} alt="" />数字人民币</MenuItem>
                    </Select>
                    <span className={classNames('', {'text-danger': inuseStatus(payment_option_select_id) == 0,'text-success':inuseStatus(payment_option_select_id) == 1})}>{payment_status[inuseStatus(payment_option_select_id)]}</span>
                </div>



                <div className="d-flex flex-column">
                    <div className="d-flex flex-column gap-4 bg-white p-4">
                        {(pay_type != 'drmb') &&
                        <div>
                            <Select className="w-100" style={{height:40}} id="pay_type" value={payment_option_select_id} onChange={(e)=>{changePaymentId(e.target.value);}}>
                                {
                                    me.payment_method && me.payment_method.map((p)=>{
                                        if(p.type == pay_type){
                                            var p_s = payment_status[inuseStatus(p.id)];
                                            if(p.type == 'card'){
                                                if(p.in_use == 1){
                                                    return <MenuItem key={p.id} value={p.id}>{p.payee_account}-{p_s}</MenuItem>
                                                }
                                            }else{
                                                return <MenuItem key={p.id} value={p.id}>{p.remark}-{p_s}</MenuItem>
                                            }
                                        }
                                    })
                                }
                                {
                                    isCard()
                                }
                            </Select>
                        </div>
                        }
                        <div className="d-flex flex-column gap-2">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('name')}</label>
                            <input className="w-100 p-2" id="payee_name" name="payee_name" value={payee_name} placeholder={t('plzname')} onChange={(e)=>setPayeeName(e.target.value)} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ? false : true}/>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('payeeaddress')}</label>
                            <div className="d-flex justify-content-between gap-3">
                                <Select style={{height:40}} className="w-100" id="selected_address" value={selected_city} onChange={(e)=>{changeCity(e.target.value);}} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ? false : true}>
                                    {
                                        address_array.map((a)=>{
                                            return <MenuItem key={a.value} value={a.value}>{a.value}</MenuItem>
                                        })
                                    }
                                </Select>
                                <Select style={{height:40}} className="w-100" id="selected_district" value={selected_district} onChange={(e)=>{changeDistrict(e.target.value);}} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ? false : true}>
                                    {
                                        district_array.map((a)=>{
                                            return <MenuItem key={a.value} value={a.value}>{a.value}</MenuItem>
                                        })
                                    }
                                </Select>
                                {/* <Select style={{height:40}} className="w-100" id="selected_area" value={selected_area} onChange={(e)=>{setSelectedArea(e.target.value);}} disabled={in_use == 0 ? true : false}>
                                    {
                                        area_array.map((a)=>{
                                            return <MenuItem key={a.value} value={a.value}>{a.value}</MenuItem>
                                        })
                                    }
                                </Select> */}
                            </div>
                        </div>
                        {pay_type == 'card' &&
                            <div className="d-flex flex-column gap-2">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{t('orgname')}</label>
                                <div className="d-flex justify-content-between gap-3">
                                    <Select style={{height:40}} className="w-100" id="payee_orgname" value={payee_orgname} onChange={(e)=>{setPayeeOrgname(e.target.value);}} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ? false : true}>
                                        {
                                            bank_array.map((b)=>{
                                                return <MenuItem key={b.value} value={b.value}>{b.value}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                        {(pay_type == 'alipay' || pay_type == 'drmb' || pay_type == 'card') &&
                        <div className="d-flex flex-column gap-2">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('accountnumber')}</label>
                            <input className="w-100 p-2" id="payee_account" name="payee_account" value={payee_account} placeholder={t('plzaccount')} onChange={(e)=>setPayeeAccount(e.target.value)} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ?false : true }/>    
                        </div>
                        }
                        {(pay_type == 'alipay' || pay_type == 'alipay_qr_p' || pay_type == 'alipay_qr_m' || pay_type == 'wechat' || pay_type == 'wechat_m') &&
                        <div className="d-flex flex-column gap-2">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('remark')}</label>
                            <input className="w-100 p-2" id="remark" name="remark" value={remark} placeholder={t('remark')} onChange={(e)=>setRemark(e.target.value)} disabled={(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) ? false : true}/>    
                        </div>
                        }
                        {(pay_type == 'wechat' || pay_type == 'wechat_m' || pay_type == 'alipay_qr_p' || pay_type == 'alipay_qr_m') &&
                            <div className="d-flex flex-column gap-2">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{t('qr')}</label>
                                <div className='d-flex flex-column gap-2 justify-content-center align-items-center' onClick={()=>document.getElementById('upload-image-btn').click()}>
                                    <input id="upload-image-btn" style={{width:0}} type="file" onChange={updateQrCode} />
                                    <img style={{width:300,objectFit:'cover'}} src={qrcode_url} className="border p-2 rounded-2" alt="" />
                                    <span>{t('addQr')}</span>
                                </div>
                            </div>
                        }
                        <div className="d-flex justify-content-center gap-5">
                            {(inuseStatus(payment_option_select_id) == 2 || inuseStatus(payment_option_select_id) == 3) &&
                                <button className="bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={sendPayBinding}>{t('bindNow')}</button>
                            }
                            {inuseStatus(payment_option_select_id) == 1 &&
                                <button className="bg-danger text-white border-0 px-5 py-2 rounded-1" onClick={sendPayUnBinding}>{t('unbindNow')}</button>
                            }
                            {/* <button className="bg-white border border-1 text-black px-5 py-2 rounded-1" onClick={reset}>{t('reset')}</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Paytype 