import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zhCN from "./locales/zh-CN";
import zhTW from "./locales/zh-TW";
import en from "./locales/en";
import ja from "./locales/ja";
import de from "./locales/de";

const resources = {
  "zh-CN": {
    translation: zhCN
  },
  "zh-TW": {
    translation: zhTW
  },
  en: {
    translation: en
  },
  ja: {
    translation: ja
  },
  de: {
    translation: de
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "zh-CN", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
});

export default i18n;