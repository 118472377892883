import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import Store from 'store'
import './settings.scss'


const Resetpassword = () => {
    const { t } = useTranslation();
    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/resetpassword`;

    const [old_password, setOldPassword] = useState('')
    const [password, setNewPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [ga_code, setGA] = useState('')

    useEffect(() => {
        
    },[])

    async function submitNewPassword(){
        var regularExpression = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+= "-])[a-zA-Z0-9!@#$%^&*()_+=-]{8,20}$/ ;
        if(!regularExpression.test(password)){
            Toast.show({
                icon: 'fail',
                content: t('newpasswordincorrectformat'),
                maskClickable: true
            })
            return;
        }

        if(password != confirm_password){
            Toast.show({
                icon: 'fail',
                content: t('oldpasswordnotmatchnewpassword'),
                maskClickable: true
            })
            return;
        }

        let params={
            old_password:old_password,
            password: password,
            confirm_password: confirm_password,
            ga_code: ga_code,
            type: 'ga'
        }

        let data = await http({ url: url, method: 'post',params:params}).then((response)=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.status == 0 && response.message == "重置密码成功"){
                Toast.show({
                    icon: 'success',
                    content: t('resetpasswordsuccess'),
                    maskClickable: true
                })
                resetData();
                return;
            }
            if(response.status == 3 && response.message == "Code is required."){
                Toast.show({
                    icon: 'fail',
                    content: t('gapasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            Toast.show({
                icon: 'fail',
                content: t('resetpasswordfailed'),
                maskClickable: true
            })
        });  
    }

    function resetData(){
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setGA('');
    }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('resetpassword')}</h4>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('oldpassword')}</label>
                        <input style={{width:200}} id="old_password" name="old_password" type="password" value={old_password} placeholder={t('pleaseinputoldpassword')} onChange={(e) => setOldPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('newpassword')}</label>
                        <input style={{width:200}} id="password" name="password" type="password" value={password} placeholder={t('pleaseinputnewpassword')} onChange={(e) => setNewPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('confirmnewpassword')}</label>
                        <input style={{width:200}} id="confirm_password" type="password" name="confirm_password" value={confirm_password} placeholder={t('pleaseconfirmnewpassword')} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('gapassword_ifany')}</label>
                        <input style={{width:200}} id="ga_code" name="ga_code" type="number" value={ga_code} placeholder={t('pleaseinputgapassword')} onChange={(e) => setGA(e.target.value)}/>
                    </div>
                    <div className="">
                        <button className="token-generate bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitNewPassword}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resetpassword 