export const b_n = ["工商银行",
"交通银行",
"农业银行",
"建设银行",
"招商银行",
"民生银行",
"上海浦东发展银行",
"北京银行",
"兴业银行",
"中信银行",
"光大银行",
"华夏银行",
"广发银行",
"平安银行",
"中国邮政",
"中国银行",
"上海银行",
"渤海银行",
"东莞银行",
"广州银行",
"宁波银行",
"广州农村商业银行",
"上海市农村商业银行",
"汉口银行",
"珠海市农村信用合作联",
"顺德农商银行",
"北京农商银行",
"东莞农村商业银行",
"桂林银行",
"内蒙古银行",
"成都银行",
"广西农村信用社",
"安徽省农村信用社",
"中原银行",
"哈尔滨银行",
"苏州银行",
"嘉兴银行",
"赣州银行",
"郑州银行",
"湖北银行",
"长沙银行",
"广东南粤银行股份有限公司",
"广西北部湾银行",
"富滇银行",
"长安银行",
"宁夏银行",
"昆仑银行",
"哈密市商业银行",
"青岛银行",
"福建农村信用社",
"河北省农村信用社",
"新疆农村信用社",
"山东省农村信用社",
"山西省农村信用社",
"内蒙古农村信用社",
"大连农商银行",
"广东农信",
"黑龙江省农村信用社",
"湖北省农村信用社",
"吉林省农村信用社",
"天津农商银行",
"贵州省农村信用社",
"深圳农村商业银行",
"浙江省农村信用社",
"湖南省农村信用社",
"海南省农村信用社",
"江西省农村信用社",
"福建农商银行",
"河南省农村信用社",
"四川省农村信用社",
"常熟农商银行",
"江苏农村商业银行",
"云南省农村信用社",
"武汉农村商业银行",
"甘肃省农村信用社",
"重庆农村商业银行",
"厦门农商银行",
"辽宁省农村信用社",
"青海省农村信用社",
"南海农商银行",
"江苏省农村信用社联合社",
"江南农商银行",
"陕西省农村信用社联合社",
"苏州农商银行",
"成都农商银行",
"贵阳农商银行",
"佛山农商银行",
"湖北鄂州农村商业银行",
"无锡农村商业银行",
"张家港农商银行",
"昆山农商银行",
"惠州农商银行",
"天津滨海农商银行",
"黄河农村商业银行",
"太仓农村商业银行",
"浙江农村商业联合银行",
"友利银行",
"杭州银行",
"浙商银行",
"东亚银行",
"温州银行",
"晋商银行",
"南京银行",
"尧都信用合作联社",
"浙江稠州商业银行",
"自贡市商业银行",
"甘肃银行",
"贵阳银行",
"龙江银行",
"泉州银行",
"锦州银行",
"大连银行",
"徽商银行",
"江苏银行",
"恒丰银行",
"河北银行",
"柳州银行",
"九江银行",
"天津银行",
"沧州银行",
"唐山银行",
"承德银行",
"张家口银行",
"秦皇岛银行",
"邢台银行",
"廊坊银行",
"保定银行",
"邯郸银行",
"衡水银行",
"大同市商业银行",
"长治银行",
"晋城银行",
"晋中银行",
"阳泉市商业银行",
"蒙商银行",
"乌海银行",
"鄂尔多斯银行",
"盛京银行",
"鞍山银行",
"抚顺银行",
"本溪市商业银行",
"丹东银行",
"营口银行",
"阜新银行",
"辽沈银行",
"铁岭银行",
"朝阳银行",
"葫芦岛银行",
"营口沿海银行",
"吉林银行",
"江苏长江商业银行",
"湖州银行",
"绍兴银行",
"金华银行",
"台州银行",
"浙江泰隆商业银行",
"浙江民泰商业银行",
"福建海峡银行",
"江西银行",
"上饶银行",
"景德镇市商业银行",
"齐鲁银行",
"齐商银行",
"烟台银行",
"潍坊银行",
"临商银行",
"威海市商业银行",
"日照银行",
"德州银行",
"莱商银行",
"东营银行",
"济宁银行",
"泰安市商业银行",
"枣庄银行",
"洛阳银行",
"焦作中旅银行",
"平顶山银行",
"湖南银行",
"珠海华润银行股份有限公司",
"广东华兴银行股份有限公司",
"重庆银行",
"重庆三峡银行",
"泸州银行",
"攀枝花市商业银行",
"宜宾市商业银行",
"乐山市商业银行",
"四川天府银行",
"长城华西银行",
"遂宁市商业银行",
"绵阳市商业银行",
"凉山州商业银行",
"雅安市商业银行",
"达州银行",
"贵州银行",
"曲靖市商业银行",
"云南红塔银行",
"西安银行",
"兰州银行",
"青海银行",
"石嘴山银行",
"乌鲁木齐市商业银行",
"新疆汇和银行",
"库尔勒市商业银行",
"宁波东海银行",
"宁波通商银行",
"厦门银行",
"厦门国际银行",
"富邦华一银行",
"国家开发银行",
"中国进出口银行",
"中国农业发展银行",
"盘锦银行",
"海南银行",
"新疆银行",
"西藏银行",
"深圳前海微众银行",
"浙江网商银行",
"上海华瑞银行",
"温州民商银行",
"天津金城银行",
"重庆富民银行",
"四川新网银行",
"湖南三湘银行",
"福建华通银行",
"武汉众邦银行",
"吉林亿联银行",
"威海蓝海银行",
"北京中关村银行",
"安徽新安银行",
"江苏苏宁银行",
"辽宁振兴银行",
"梅州客商银行",
"中国人民银行",
"江西裕民银行",
"无锡锡商银行",
"汇丰银行",
"广饶梁邹村镇银行",
"四川银行",
"东营莱商村镇银行",
"莫力达瓦包商村镇银行",
"讷河融兴村镇银行",
"秦农银行",
"扎兰屯蒙银村镇银行",
"福建福鼎恒兴村镇银行",
"赛罕蒙银村镇银行",
"玉泉蒙银村镇银行",
"新城蒙银村镇银行",
"海南保亭融兴村镇银行",
"黔西花都村镇银行",
"中山农村商业银行",
"重庆市酉阳融兴村镇银行",
"中银富登村镇银行",
"樟树顺银村镇银行",
"山西银行",
"韶关农商银行",
"上海崇明沪农商村镇银行",
"山东博兴新华村镇银行",
"鄞州银行",
"海口联合农商银行",
"恒生银行",
"晋江农信银行",
"永春农信银行",
"鄢陵郑银村镇银行",
"新华村镇银行",
"花旗银行",
"昌乐村镇银行",
"尉氏合益村镇银行",
"沂源博商村镇银行",
"桂林国民村镇银行",
"上栗富民村镇银行",
"垦利乐安村镇银行",
"固始天骄村镇银行",
"松桃长征村镇银行",
"贵州印江长征村镇银行",
"深圳福田银座村镇银行",
"内江兴隆村镇银行",
"江西赣州银座村镇银行",
"重庆黔江银座村镇银行",
"平和润丰村镇银行",
"南洋商业银行",
"濮阳中原村镇银行",
"淮南通商农商银行",
"平潭农商银行",
"周至农科村镇银行",
"宁安融兴村镇银行",
"当阳兴福村镇银行",
"新韩银行",
"林州中原村镇银行",
"贵州省万山长征村镇银行",
"霍邱联合村镇银行",
"天津宁河村镇银行",
"文山民丰村镇银行",
"桦南融兴村镇银行",
"江阴农商银行",
"长春双阳吉银村镇银行",
"河间融惠村镇银行",
"山东蒙阴齐丰村镇银行",
"前郭县阳光村镇银行",
"渣打银行",
"广州白云民泰村镇银行",
"紫金农商银行",
"广东英德泰隆村镇银行",
"莆田农商银行",
"蛟河吉银村镇银行",
"韩亚银行",
"浙江义乌联合村镇银行",
"定海德商村镇银行",
"上海松江富明村镇银行",
"临猗新田村镇银行",
"双辽吉银村镇银行",
"深圳南山宝生村镇银行",
"重庆北碚稠州村镇银行",
"农安北银村镇银行",
"乌苏利丰村镇银行",
"九台龙嘉村镇银行",
"松原宁江惠民村镇银行",
"天津华明村镇银行",
"山东省泗水县齐丰村镇银行",
"蒙自沪农商村镇银行",
"珠海横琴村镇银行",
"星展银行",
"昌吉国民村镇银行",
"合浦国民村镇银行",
"长春绿园融泰村镇银行",
"洪洞洪都村镇银行",
"潍城北海村镇银行",
"青隆村镇银行",
"辽源龙山榆银村镇银行",
"长春二道农商村镇银行",
"云南元江北银村镇银行",
"卓资蒙银村镇银行",
"深圳龙华新华村镇银行",
"平遥晋融村镇银行",
"重庆渝北银座村镇银行",
"石河子国民村镇银行",
"曲靖惠民村镇银行",
"邛崃国民村镇银行",
"涟源沪农商村镇银行",
"哈尔滨农村商业银行",
"北京房山沪农商村镇银行",
"洮南惠民村镇银行",
"开鲁蒙银村镇银行",
"临高惠丰村镇银行",
"克拉玛依金龙国民村镇银行",
"天津滨海德商村镇银行",
"龙井榆银村镇银行",
"江西上栗富民村镇银行",
"缙云联合村镇银行",
"萍乡安源富民村镇银行",
"河北蠡州北银农商银行",
"辽宁喀左锦银村镇银行",
"雄县丰源村镇银行",
"桂阳沪农商村镇银行",
"广西浦北国民村镇银行",
"瑞信村镇银行",
"济南槐荫沪农商村镇银行",
"霸州舜丰村镇银行",
"珠海农商银行",
"遂平中原村镇银行",
"柳林汇泽村镇银行",
"防城国民村镇银行",
"灵丘長青村镇银行",
"盘山安泰村镇银行",
"尧都农商银行",
"南阳村镇银行",
"望谟富民村镇银行",
"陵水惠民村镇银行",
"北京顺义银座村镇银行",
"广西钦南国民村镇银行",
"金寨徽银村镇银行",
"延吉和润村镇银行",
"玉溪澄江中成村镇银行",
"临朐村镇银行",
"深泽利丰村镇银行",
"通许融信村镇银行",
"怀来利丰村镇银行",
"中牟郑银村镇银行",
"广西银海国民村镇银行",
"公主岭华兴村镇银行",
"乐清联合村镇银行",
"昆明晋宁融丰村镇银行",
"阿拉尔津汇村镇银行",
"东平沪农商村镇银行",
"兰西农商村镇银行",
"长子县融汇村镇银行"]