import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import http from '../lib/http'
import useSWR from 'swr'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Header from "../components/header"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../App.scss"
import { set } from 'store';
import { useTranslation } from 'react-i18next'
import Store from 'store'

function createData(id,amount,total_amount,fee,subject,time) {
    return { id, amount, total_amount, fee, subject,time };
}

const TransactionLog = () => {
    const { t } = useTranslation();

    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/transaction-log`;
    const [data,setData] = useState([]);
    const { search } = useLocation();
    const [direction,setDirection] = useState('');

    const [startDate, setStartDate] = useState(); 
    const [endDate, setEndDate] = useState(); 
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [type, setType] = useState(0);
    const [finalType, setFinalType] = useState([0]);

    useEffect(async() => {
        updateTableDate(page,rowsPerPage);
    },[]);

    useEffect(async() => {
        setFinalType([type]) ;
        if(type==2){
            setFinalType([2,3]) ;
        }
        if(type==4){
            setFinalType([4,5,6]) ;
        }
    },[type]);

    useEffect(async() => {
        updateTableDate(page,rowsPerPage);
    },[finalType]);

    const updateTableDate = (_page,_rowsPerPage) => {
        var params = {
            "startDate":startDate,
            "endDate":endDate,
            "page":parseInt(_page)+1,
            "limit":_rowsPerPage,
            "type":finalType
        };
        if(params['startDate'] != null){
            params['startDate'] = new Date(params['startDate'].setHours(0,0,0,0));
            params['startDate'] = new Date(params['startDate'].getTime() + (8*60*60*1000));
        }
        if(params['endDate'] != null){
            params['endDate'] = new Date(params['endDate'].setHours(23,59,0,0));
            params['endDate'] = new Date(params['endDate'].getTime() + (8*60*60*1000));
        }

        http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.data.lists!=0){
                const temp_row = response.data.lists.data;
                setRows(temp_row);
                setTotalRowCount(response.data.lists.total_rows_count);
                setTotalAmount(response.data.amount);
                setTotalDiscount(response.data.discount);
                setTotalFee(response.data.fee);
            }
        });
    }

    const handleChangePage = (event, newPage) => {
        console.log('newpage',newPage);
        setPage(newPage);
        updateTableDate(newPage,rowsPerPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        console.log('handleChangeRowsPerPage',event.target.value)
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        updateTableDate(0,event.target.value);
      };

    function typeMassage(type){
        switch (type){
            case 1:
                return t('merchanttransferto');
            case 2:
            case 3:
                return t('merchanttransferout');
            case 4:
            case 5:
            case 6:
                return t('systemreturns');
            case 7:
                return t('merchanttransferto');
            case 8:
                return t('merchanttransferout');
            case 9:
                return t('merchanttransferto');
            case 12:
                return t('merchantreceivesrecharge');
            case 15:
                return t('merchantwithdrawscurrency');
            case 16:
                return t('administratortransfersin');
            case 17:
                return t('administratortransfersout');
            case 18:
                return t('depositrebate');
            case 19:
                return t('buyorderrebate');
            case 20:
                return t('makerdeductusdt');
            case 21:
                return t('takerdeductusdt');
            case 22:
                return t('merchantmarkupdeduct');
            case 23:
                return t('offerAdd');
            case 24:
                return t('offerDeduct');
            default:
                return type;

        };
    }

    function selectDate(_date,_type){
        if(_type=="start")setStartDate(_date);
        if(_type=="end")setEndDate(_date);
        console.log(_date,_type);
    }

    function timeZoneConvert(date){
        var d = new Date(date);
        return d.toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong', hour12: false })
    }

    function changeType(_type){
        setType(_type);
    }

    function saveDataInCSV(fileName = 'export.csv'){
        console.log('saveDataInCSV',rows);
        const data = JSON.parse(JSON.stringify(rows));

        if (data.length === 0) {
          console.warn('Data is empty.');
          return;
        }

        data.map((item) => {
            item[t('totalbalance')] = item['current_usdt']  ;
            delete item['current_usdt'] ;

            item[t('createtime')] = timeZoneConvert(item['created_at'])  ;
            delete item['created_at'] ;

            item[t('numberofcoins')] = item['total_amount']  ;
            delete item['total_amount'] ;

            item[t('fee')] = item['fee']  ;
            delete item['fee'] ;

            item[t('remarks')] = typeMassage(item['type'])  ;
            delete item['type'] ;

            delete item['updated_at'];
            delete item['user_id'];
            delete item['sn'];
        });

      
        const propertyNames = Object.keys(data[0]);
        const rowWithPropertyNames = propertyNames.join(',') + '\n';
      
        let csvContent = rowWithPropertyNames;
      
        const r = [];
      
        data.forEach((item) => {
          const values = [];
      
          propertyNames.forEach((key) => {
            let val = item[key];
      
            if (val !== undefined && val !== null) {
              val = new String(val);
            } else {
              val = '';
            }
            values.push(escapeCsvValue(val));
          });
      
          r.push(values.join(','));
        });
      
        csvContent += r.join('\n');
      
        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a link element and trigger a download
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
      
          // Append the link to the body
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else {
          console.error('Download is not supported in this browser.');
        }
      }
    
      function escapeCsvValue(value){
        // If the value contains a comma, newline, or double quote, wrap it in double quotes
        if (value.includes(',') || value.includes('\n') || value.includes('"')) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4 className="text-dark-blue">{t('walletflow')}</h4>
                <div className="w-100 d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                            <span>{t('timequery')}</span>
                            <div style={{width:100}}>
                                <DatePicker className="w-100" selected={startDate} onChange={(date) => setStartDate(date)} />
                            </div>
                            <span>{t('to')}</span>
                            <div style={{width:100}}>
                                <DatePicker className="w-100" selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                            <button className="bg-dark-blue text-white px-3 rounded-2 border-0 fs-6" onClick={()=>updateTableDate(page,rowsPerPage)}>{t('inquiry')}</button>
                        </div>
                        <div className='d-flex gap-2 align-items-center border border-2 border-secondary p-2' role="button" onClick={()=>saveDataInCSV()}>
                            <i className="fa-solid fa-download"></i>
                            <span>{t('downloadlist')}</span>
                        </div>
                    </div>
                    <div>
                        <Select style={{height:40}} id="type" value={type} onChange={(e)=>{changeType(e.target.value);}}>
                            <MenuItem value='0'>全選</MenuItem>
                            {
                                [1,2,4,7,8,9,12,15,16,17,18,19,20,21,22,23,24].map((item,index)=>{
                                    return <MenuItem key={index} value={item}>{typeMassage(item)}</MenuItem>
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className="h-100 overflow-auto">
                    <TableContainer component={Paper}>
                        <Table className="w-100" aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">{t('createtime')}</TableCell>
                                <TableCell align="right">{t('numberofcoins')}</TableCell>
                                <TableCell align="right">{t('realnumberofcoins')}</TableCell>
                                <TableCell align="right">{t('totalbalance')}</TableCell>
                                <TableCell align="right">{t('fee')}</TableCell>
                                <TableCell align="right">{t('remarks')}</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">{timeZoneConvert(row.created_at)}</TableCell>
                                <TableCell align="right">{(Number(row.total_amount)+Math.abs(Number(row.fee))).toFixed(2)}</TableCell>
                                <TableCell align="right">{Number(row.total_amount)}</TableCell>
                                <TableCell align="right">{row.current_usdt}</TableCell>
                                <TableCell align="right">{row.fee}</TableCell>
                                <TableCell align="right">{typeMassage(row.type)}</TableCell>
                                
                                </TableRow>
                            ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow className='border border-top'>
                                    <TableCell component="th" scope="row">{t('total')}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{totalAmount}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">{totalFee}</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={6}
                                    count={totalRowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t('everypageshow')}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                                </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default TransactionLog