import { InfiniteScroll, List, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import './settings.scss'
import Store from 'store'


const Webwhitelistsettings = () => {

    const [token, setToken] = useState('')
    const [whitelists, setWhitelists] = useState('')
    const [name, setName] = useState('')

    const { t } = useTranslation()
    var state = {
        token: '',
        name: '',
        whitelists:'',
      }
    
    const {data:listData, error:err}  = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/whitelist_settings`, (url) => http({ url: url, method: 'POST'}), {revalidateOnFocus: false, errorRetryCount: 2})
    
    //generate random key function
    const generateRandomKey = () => {
        var randomKey = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 32; i++)
          randomKey += possible.charAt(Math.floor(Math.random() * possible.length));
        //return randomKey;
        setToken(randomKey)
    }

    //submit whitelist function
    const submitWhiteList = () => {
        http({ url: process.env.REACT_APP_API_URL+'/agentbackend/user/agent/save_whitelist_settings', method: "POST", data: {"whitelists":whitelists} })
        .then(res => {
            console.log("res",res);
            if(res.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(res.message=='ok'){
                Toast.show({
                    icon: 'success',
                    content: t('settingupdatesuccess'),
                    maskClickable: true
                })
                return;
            }else{
                Toast.show({
                    icon: 'fail',
                    content: t(res.message),
                    maskClickable: true
                })
                return;
            }
        })
        .catch(err => {
            console.log("err",err);
        })
    }

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }
        if (listData && listData.status === 0 && listData.data!=null && listData.data!=undefined) {
            const { data: {data} } = listData
            console.log("ListData",listData);
            setWhitelists(listData.data.value)
        }

    }, [listData, err])
    
    const handleChange = event => {
        if(event.target.id=='name')
            setName(event.target.value);
        else if(event.target.id=='whitelists')
            setWhitelists(event.target.value);
    };

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('list5Title')}</h4>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="d-flex flex-row gap-2">
                        <label>{t('whitelist_input')}</label>
                        <div className='d-flex flex-column gap-2'>
                            <textarea className="col" name="whitelists" id="whitelists" value={whitelists} rows={5} cols={40} onChange={handleChange}/>
                            <span>{t('whitelist_hints')}</span>
                        </div>
                    </div>
                    <div className="text-end">
                        <button className="token-generate bg-dark-blue text-white border-0 px-4 pt-2 pb-2 rounded-1" onClick={submitWhiteList}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Webwhitelistsettings 