import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import Store from 'store'
import './settings.scss'


const Resetsubpassword = () => {
    const { t } = useTranslation();
    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/set-sub-password`;

    const [old_password, setOldPassword] = useState('')
    const [new_password, setNewPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [ga_code, setGA] = useState('')

    useEffect(() => {

    },[])

    function is_valid_6_digit(_input_string){
        const sequential = "0123456789876543210" ;
        return sequential.includes(_input_string) ;
    }

    async function submitNewPassword(){
        var tradepwd_regex = /^(?!.*(\d)\1{1,})\d{6}$/ ;
        if(!tradepwd_regex.test(new_password) || is_valid_6_digit(new_password)){
            Toast.show({
                icon: 'fail',
                content: t('newsubpasswordincorrectformat'),
                maskClickable: true
            })
            return;
        }
        if(new_password != confirm_password){
            Toast.show({
                icon: 'fail',
                content: t('oldsubpasswordnotmatchnewsubpassword'),
                maskClickable: true
            })
            return;
        }

        let params={
            old_password:old_password,
            sub_password:new_password,
            confirm_password: confirm_password,
            ga_code: ga_code,
            type: 'ga'
        }

        let data = await http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.status == 0 && response.msg == "success"){
                Toast.show({
                    icon: 'success',
                    content: t('resetsubpasswordsuccess'),
                    maskClickable: true
                })
                resetData();
                return;
            }
            if(response.status == 101 && response.message == "Incorrect GA"){
                Toast.show({
                    icon: 'fail',
                    content: t('gapasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            Toast.show({
                icon: 'fail',
                content: t('resetpasswordfailed'),
                maskClickable: true
            })
        });  
    }

    function resetData(){
        setOldPassword('') ;
        setNewPassword('') ;
        setConfirmPassword('') ;
        setGA('') ;
    }

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('resetsubpassword')}</h4>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('oldsubpassword')}</label>
                        <input style={{width:200}} id="old_password" type="number" name="old_password" value={old_password} placeholder={t('pleaseinputoldsubpassword')} onChange={(e) => setOldPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('newsubpassword')}</label>
                        <input style={{width:200}} id="new_password" type="number" name="new_password" value={new_password} placeholder={t('pleaseinputnewsubpassword')} onChange={(e) => setNewPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('confirmnewsubpassword')}</label>
                        <input style={{width:200}} id="confirm_password" type="number" name="confirm_password" value={confirm_password} placeholder={t('pleaseconfirmnewsubpassword')} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <label style={{width:150}}>{t('gapassword_ifany')}</label>
                        <input style={{width:200}} id="ga_code" name="ga_code" type="number" value={ga_code} placeholder={t('pleaseinputgapassword')} onChange={(e) => setGA(e.target.value)}/>
                    </div>
                    <div className="">
                        <button className="token-generate bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitNewPassword}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resetsubpassword 