import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import Store from 'store'
import './settings.scss'


const Settings = () => {

    const [token, setToken] = useState('')
    const [whitelists, setWhitelists] = useState('')
    const [name, setName] = useState('')

    const { t } = useTranslation()
    var state = {
        token: '',
        name: '',
        whitelists:'',
      }
    
    const {data:listData, error:err}  = useSWR(`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/settings`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})
    
    //generate random key function
    const generateRandomKey = () => {
        var randomKey = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 32; i++)
          randomKey += possible.charAt(Math.floor(Math.random() * possible.length));
        //return randomKey;
        setToken(randomKey)
    }

    //submit whitelist function
    const submitWhiteList = () => {
        http({ url: process.env.REACT_APP_API_URL+'/agentbackend/user/agent/save_settings', method: "POST", data: { "name":name,"token":token, "whitelists":whitelists} })
        .then(res => {
            if(res.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(res.message=='ok'){
                Toast.show({
                    icon: 'success',
                    content: t('settingupdatesuccess'),
                    maskClickable: true
                })
                return;
            }else{
                Toast.show({
                    icon: 'fail',
                    content: t(res.message),
                    maskClickable: true
                })
                return;
            }
        })
        .catch(err => {
            console.log("err",err);
        })
    }

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }

        http({ url: `${process.env.REACT_APP_API_URL}/agentbackend/user/agent/settings`, method: "POST" })
        .then(res => {
            setToken(res.data.api_key);
            setName(res.data.name);
            setWhitelists(res.data.whitelists);
            
        })
        .catch(err => {
            console.log("err",err);
        })

        if (listData && listData.status === 0) {
            const { data: {data} } = listData
            console.log("ListData",listData);
        }

    }, [listData, err])
    
    const handleChange = event => {
        if(event.target.id=='name')
            setName(event.target.value);
        else if(event.target.id=='whitelists')
            setWhitelists(event.target.value);
    };

    return (
        <div className="d-flex">
            <Header></Header>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('list4Title')}</h4>
                <div className="w-100 d-flex flex-column gap-4 vh-100">
                    <div className="w-75 d-flex flex-row gap-2">
                        <label>{t('name_input')}</label>
                        <input maxlength="20" className="w-75" id="name" name="name" value={name} placeholder={t('name_placeholder')} onChange={handleChange}/>
                    </div>
                    <div className="w-100 d-flex flex-row justify-content-between" >
                        <div className="w-75 d-flex flex-row gap-2">
                            <label>{t('token_input')}</label>
                            <input className="w-75" value={token} name="token" placeholder={t('token_placeholder')} onChange={handleChange}/>
                            <button onClick={generateRandomKey} className="token-generate">{t('obtain')}</button>
                        </div>
                        
                    </div>
                    <div className="d-flex flex-row gap-2">
                        <label>{t('whitelist_input')}</label>
                        <div className='d-flex flex-column gap-2'>
                            <textarea className="col" name="whitelists" id="whitelists" value={whitelists} rows={5} cols={40} onChange={handleChange}/>
                            <span>{t('whitelist_hints')}</span>
                        </div>
                        
                    </div>
                    <div className="text-end">
                        <button className="token-generate bg-dark-blue text-white border-0 px-4 pt-2 pb-2 rounded-1" onClick={submitWhiteList}>{t('submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings 