import axios from 'axios';
import qs from "qs";
import Store from 'store';

const http = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

http.interceptors.request.use(config => {
  if (Store.get('user') && Store.get('user').access_token) {
    config.headers.Authorization = "Bearer" + Store.get('user').access_token
  }
  console.log(qs.stringify({...config.data}));
  config.method = config.method ? config.method: 'post';
  config.method === 'post'
        ? config.data = qs.stringify({...config.data})
        : config.params = {...config.params};
  return config;
});

http.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data
    } else {
      console.error(response.data, '1')
    }
  },
  error => {
    // if (error.status)
    const errRes = error.response;
    if (errRes.status === 401) {
      window.location.href = "/"
    }
    console.log(JSON.stringify(error), '2');
    return Promise.reject(error)
  }
)

export default http;