import {Toast } from 'antd-mobile/2x'
import {useEffect, useState,useMemo } from 'react'
import Header from '../components/header'
import http from '../lib/http'
import './transfer.scss'
import { useTranslation } from 'react-i18next'
import payment_alipay from '../assets/payment_alipay.png'
import payment_card from '../assets/payment_card.png'
import payment_drmb from '../assets/payment_drmb.png'
import payment_qq from '../assets/payment_qq.png'
import payment_unionpay from '../assets/payment_unionpay.png'
import payment_wechat from '../assets/payment_wechat.png'
import classNames from 'classnames';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import bignumber from 'bignumber.js';
import { fontSize } from '@mui/system'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const NewTask = () => {
    const { t } = useTranslation();

    const url = `${process.env.REACT_APP_API_URL}`;
    const me_url =`${process.env.REACT_APP_API_URL}/agentbackend/user/me`;

    const [me, setMyInfo] = useState({})
    const [goal, setGoal] = useState('')
    const [discount, setDiscount] = useState(0)
    const [display_discount, setDisplayDiscount] = useState(0)
    const [tab, setTab] = useState(2)
    const [price_title, setPriceTitle] = useState('')
    const [special_min, setSpecial_min] = useState(0)
    const [special_max, setSpecial_max] = useState(2)
    const [min_usdt_limit, setMin_usdt_limit] = useState(0)
    const [max_usdt_limit, setMax_usdt_limit] = useState(0)
    const [can_card, set_can_card] = useState(0)
    const [can_alipay, set_can_alipay] = useState(0)
    const [can_wechat, set_can_wechat] = useState(0)
    const [can_drmb, set_can_drmb] = useState(0)
    const [can_qq, set_can_qq] = useState(0)
    const [can_unionpay, set_can_unionpay] = useState(0)
    const [unitprice, setUnitprice] = useState(1)
    const [slider_option, setSlider_option] = useState({})
    const [split, setSplit] = useState(0)
    const [subpassword, setSubPassword] = useState('')

    const [pay_drmb_flag, setDrmbflag] = useState(0)
    const [pay_alipay_flag, setAlipayflag] = useState(0)
    const [pay_wechat_flag, setWechatflag] = useState(0)
    const [pay_card_flag, setCardflag] = useState(0)
    const [pay_qq_pay_flag, setQqpayflag] = useState(0)
    const [pay_union_pay_flag, setUnionpayflag] = useState(0)

    const [target_card, setTargetCard] = useState(''); //if more than 1 card can be select , pick 1

    const [availableUsdt, setAvailableUsdt] = useState(0)
    const [myUsdt, setMyUsdt] = useState(0)

    //submit whitelist function
    const submitTransfer = async()=>{
        if(!goal || goal<=0){
            alert('数量不能为空')
            return ;
        }

        var accept = [];
        var up = unitprice + (unitprice*(discount/100));
        
        if(can_alipay==1){
            let result=me.payment_method.filter((p)=>p.type=='alipay')[0].id;
            accept.push(result)
        }
        if(can_wechat==1){
            let result=me.payment_method.filter((p)=>p.type=='wechat')[0].id;
            accept.push(result)
        }
        if(can_drmb==1){
            let result=me.payment_method.filter((p)=>p.type=='drmb')[0].id;
            accept.push(result)
        }
        if(can_qq==1){
            let result=me.payment_method.filter((p)=>p.type=='qq_pay')[0].id;
            accept.push(result)
        }
        if(can_unionpay==1){
            let result=me.payment_method.filter((p)=>p.type=='union_pay')[0].id;
            accept.push(result)
        }
        if(can_card==1){
            if(tab == 1 && me.payment_method.filter((p)=>p.type=='card').length > 1){
                let result = target_card ;
                accept.push(result)
            }else{
                let result=me.payment_method.filter((p)=>p.type=='card')[0].id;
                accept.push(result)
            }
        }

        accept=accept.join(',');
        
        if(accept.length <= 0){
            alert(t('selectPaymentToReceiveMethod'));
            return ;
        }

        if(tab == 1){
            if(!pay_card_flag && !pay_alipay_flag && !pay_wechat_flag && !pay_drmb_flag && !pay_qq_pay_flag&& !pay_union_pay_flag){
                alert(t('firstset'));
            }

            var feerate=me.config.otc_sell_feerate;
            var highFee=false;
            var _usdt=new bignumber(goal).toNumber();
            var fee=_usdt*feerate;

            let title=t('FeeTitle');
            let content=t('FeeContent')+(feerate*100).toString()+'%('+fee.toFixed(2)+'V币)'+t('FeeContentAfter');
            if(_usdt+fee>myUsdt+availableUsdt){
                alert(t('maxlimit'))
                return;
            }
            if(availableUsdt<_usdt){
                highFee=true;
                feerate=me.config.otc_sell_high_feerate;
                fee=_usdt*feerate;
                title=t('HighFeeTitle');
                content=t('highFeeContent')+(feerate*100).toString()+'%('+fee.toFixed(2)+'V币)'+t('highFeeContentAfter');
            }

            if(min_usdt_limit>goal){
                alert(t('mintradeincorrect'));
                return;
            }
            if (!subpassword) {
                alert(t('pwdIsEmpty'));
                return
            }
        }

        if(tab == 2){
            if(me.permission.buy_limitation >= 1){
                if(goal < me.permission.buy_limitation_lower){
                    alert(t('buylimitationlower'));
                    return;
                }
                if(goal > me.permission.buy_limitation_upper){
                    alert(t('buylimitationupper'));
                    return;
                }
            }
        }

        var params = {
            tab:tab,   //tab 2 = 挂单购买 , tab 1 = 挂单出售
            goal:goal,
            unitprice:up,
            min_usdt_limit:min_usdt_limit,
            max_usdt_limit:max_usdt_limit,
            can_card:can_card,
            can_alipay:can_alipay,
            can_wechat:can_wechat,
            can_drmb:can_drmb,
            can_qq_pay:can_qq,
            can_union_pay:can_unionpay,
            discount:discount,
            accept:accept,
            split:split,
            sub_password:subpassword,
        };
        console.log(params);

        if(tab == 2){
            var u = url + '/webapp/otc/task-buy';
        }else{
            var u = url + '/webapp/otc/task-sell';
        }
        await http({ url: u, method: 'post',params:params}).then(response=>{
            console.log(response);
            if(response.status == 0 && response.data.id){
                Toast.show({
                    icon: 'success',
                    content: t('tasklistingsuccess'),
                    maskClickable: true
                })
                reset();
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                return;
            }else{
                alert('Something went wrong');
            }
        });  
    }

    function updateGoal(_value){
        console.log(_value);
        const pattern = /^\d+(\.\d{0,2})?$/;
        if(pattern.test(_value)){
            setGoal(_value);
        }else if(_value<=0){
            setGoal('');
        }

        if(tab === 1){ //tab 2 = 挂单购买 , tab 1 = 挂单出售
            if(_value <= 0 || _value == undefined || _value == ''){
                setSpecial_min(0) ;
                setSpecial_max(0) ;
                return ;
            }

            for(var i = 0;i < slider_option.upper.length; i++){
                if(_value <= parseInt(slider_option.upper[i])){
                    console.log(slider_option);
                    var smin = slider_option.min_percent[i]*100 ;
                    var smax = slider_option.max_percent[i]*100 ;
                    setSpecial_min(smin);
                    setSpecial_max(smax);
                    if(0 > smin && 0 < smax){
                        var d = 0 ;
                    }else{
                        var d = smin ;
                    }
                    setDiscount(d) ;
                    updateTitle(d);
                    setDisplayDiscount(Math.abs(d));
                    return;
                }
            }
        }
    }

    function addNewPayment(_msg){
        confirmAlert({
            title: _msg,
            message: t('bind_new_payment'),
            buttons: [
                {
                    label: t('cancel_set'),
                    onClick: () => {}
                },
                {
                    label: t('confirm_set'),
                    onClick: () => window.location.href = '/paytype'
                },
              
            ]
          });
    }

    function updatePayment(_type){

        if(tab == 1){
            if(_type == 'card' && pay_card_flag <= 0){
                addNewPayment('请先开启银行卡支付');
                return;
            }
            if(_type == 'alipay' && pay_alipay_flag <= 0){
                addNewPayment('请先开启支付宝支付');
                return;
            }
            if(_type == 'wechat' && pay_wechat_flag <= 0){
                addNewPayment('请先开启微信支付');
                return;
            }
            if(_type == 'drmb' && pay_drmb_flag <= 0){
                addNewPayment('请先开启DRMB支付');
                return;
            }
            if(_type == 'qq' && pay_qq_pay_flag <= 0){
                addNewPayment('请先开启QQ支付');
                return;
            }
            if(_type == 'unionpay' && pay_union_pay_flag <= 0){
                addNewPayment('请先开启银联支付');
                return;
            }
        }
        console.log(_type);
        if(_type === 'card')set_can_card(can_card===0?1:0);
        if(_type === 'alipay')set_can_alipay(can_alipay===0?1:0);
        if(_type === 'wechat')set_can_wechat(can_wechat===0?1:0);
        if(_type === 'drmb')set_can_drmb(can_drmb===0?1:0);
        if(_type === 'qq')set_can_qq(can_qq===0?1:0);
        if(_type === 'unionpay')set_can_unionpay(can_unionpay===0?1:0);
    }

    function updateDiscount(_value){
        setDiscount(_value);
        setDisplayDiscount(Math.abs(_value));
        setTimeout(() => {
            updateTitle(_value);
        },0);
    }

    function updateTitle(_value){
        if(_value>0){
            setPriceTitle(t('upPriceTitle'));
        }else if(_value<0){
            setPriceTitle(t('downPriceTitle'));
        }else{
            setPriceTitle(t('priceTitle'));
        }
    }

    function splitTask(_event){
        console.log(_event);
        setSplit(_event.target.value);
    }

    function reset(){
        setGoal('');
        setSplit(0);
    }

    useEffect(async() => {
        await http({ url: me_url, method: 'post'}).then(response=>{
            if(response.status == 0){
                setMyInfo(response.data);
                console.log(response.data);
                setSlider_option(response.data.config.range);

                var myAvailableUsdt=new bignumber(response.data.available_usdt||0).toNumber();
                var heldAvailableUsdt = new bignumber(response.data.held_available_usdt||0).toNumber();
                setAvailableUsdt(Math.max(myAvailableUsdt-heldAvailableUsdt,0));
                setMyUsdt(new bignumber(response.data.usdt).toNumber()-(new bignumber(response.data.locked_goal).toNumber()+new bignumber(response.data.locked_fee).toNumber()-heldAvailableUsdt));
                
                for(let i=0;i<response.data.payment_method.length;i++){
                    if(response.data.payment_method[i].in_use == 0)continue;
                    switch(response.data.payment_method[i].type){
                        case 'drmb':
                            setDrmbflag(pay_drmb_flag+1);
                            break;
                        case 'alipay':
                            setAlipayflag(pay_alipay_flag+1);
                            break;
                        case 'wechat':
                            setWechatflag(pay_wechat_flag+1);
                            break;
                        case 'card':
                            setCardflag(pay_card_flag+1);
                            break;
                        case 'qq_pay':
                            setCardflag(pay_qq_pay_flag+1);
                            break;
                        case 'union_pay':
                            setUnionpayflag(pay_union_pay_flag+1);
                            break;
                        default:
                            break;
                    }
                }
            }
        });
    },[])
    

    return (
        <div className="d-flex">
            <Header></Header>
            <div style={{maxWidth:800}} className="w-100 p-4 d-flex flex-column gap-4 vh-100 overflow-scroll">
                <h4  className="text-dark-blue">{t('neworder')}</h4>
                <div className="d-flex flex-column">
                    <div className="d-flex bg-white rounded-top-3 overflow-hidden border-bottom">
                        <div role="button" className={'w-50 p-3 text-center ' + (tab===2?'bg-dark-blue text-white':'')} onClick={()=>{setTab(2);reset();}}>
                            {t('buyorder')}
                        </div>
                        <div role="button" className={'w-50 p-3 text-center ' + (tab===1?'bg-dark-blue text-white':'')} onClick={()=>{setTab(1);reset();}}>
                            {t('sellorder')}
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-4 bg-white p-4">
                        <div className="d-flex flex-column gap-2">
                            <div className="w-100 d-flex gap-4 align-items-center">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{tab===2?t('buyvtoken'):t('sellvtoken')}</label>
                                <input style={{maxWidth:500}} className="w-100 p-2" id="goal" name="goal" value={goal} placeholder={tab===2?t('buytokenplaceholder'):t('selltokenplaceholder')} onChange={(e)=>updateGoal(e.target.value)}/>
                            </div>
                            {tab == 1 && 
                                <div className='d-flex flex-column gap-2'>
                                    <span style={{fontSize:12}}>{t('availablesellnum')}: {availableUsdt}{t('unit')}</span>
                                    {availableUsdt-goal <0 &&
                                        <span style={{fontSize:12}}>{t('noavailablesellnum')}：{myUsdt}{t('unit')}</span>
                                    }
                                </div>
                            }
                        </div>
                        <div className="w-100 d-flex flex-column gap-2">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('upperlowerlimit')}</label>
                            <div className="d-flex gap-2">
                                <input type="range" min={special_min} max={special_max} value={discount} className="w-100 p-2" id="discount" name="discount" placeholder="0" onChange={(e)=>updateDiscount(e.target.value)}/>
                                <div className="p-2 rounded-2 bg-body-secondary text-center d-flex gap-1 d-flex justify-content-end gap-3" style={{minWidth:100}}>
                                    <span className="fs-7">{price_title}</span>
                                    <span type="text">{display_discount}%</span>
					            </div>
                            </div>
                        </div>
                        {tab == 1 &&
                            <div className='d-flex flex-column gap-2'>
                                <div className='d-flex gap-4 align-items-center'>
                                    <label><span className="text-danger">* </span>{t('split_task')}</label>
                                    <FormControl>
                                        <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="0" name="radio-buttons-group" onChange={(e)=>setSplit(e.target.value)}>
                                            <FormControlLabel value="0" control={<Radio />} label={t('split_task_no')} />
                                            <FormControlLabel value="1" control={<Radio />} label={t('split_task_yes')} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {split == 1 &&
                                    <div className="d-flex flex-row gap-2 align-items-center">
                                        <label style={{minWidth:150}}><span className="text-danger">* </span>{t('mintrade')}{t('unit')}</label>
                                        <input style={{maxWidth:500}} className="w-100 p-2" id="min_usdt_limit" name="min_usdt_limit" value={min_usdt_limit} placeholder={t('oncemin')} onChange={(e)=>setMin_usdt_limit(e.target.value)}/>
                                    </div>
                                }
                            </div>
                        }
                        <div className="d-flex flex-column gap-2">
                            <div>
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{tab===2?t('buytokenoption'):t('selltokenoption')}</label>
                            </div>
                            <div className="w-100 d-flex gap-4 align-items-center">
                                <div className={classNames('w-100', {'border p-2': can_alipay === 1,'opacity-25':(tab == 1 && pay_alipay_flag <= 0)})} onClick={()=>updatePayment('alipay')}>
                                    <img className="w-100" src={payment_alipay} alt="" />
                                </div>
                                <div className={classNames('w-100 text-center', {'border p-2': can_card === 1,'opacity-25':(tab == 1 && pay_card_flag <= 0)})} onClick={()=>updatePayment('card')}>
                                    <img className="w-75" src={payment_card} alt="" />
                                </div>
                                <div className={classNames('w-100', {'border p-2': can_drmb === 1,'opacity-25':(tab == 1 && pay_drmb_flag <= 0)})} onClick={()=>updatePayment('drmb')}>
                                    <img className="w-100" src={payment_drmb} alt="" />
                                </div>
                                <div className={classNames('w-100', {'border p-2': can_qq === 1,'opacity-25':(tab == 1 && pay_qq_pay_flag <= 0)})} onClick={()=>updatePayment('qq')}>
                                    <img className="w-100" src={payment_qq} alt="" />
                                </div>
                                <div className={classNames('w-100', {'border p-2': can_unionpay === 1,'opacity-25':(tab == 1 && pay_union_pay_flag <= 0)})} onClick={()=>updatePayment('unionpay')}>
                                    <img className="w-100" src={payment_unionpay} alt="" />
                                </div>
                                <div className={classNames('w-100', {'border p-2': can_wechat === 1,'opacity-25':(tab == 1 && pay_wechat_flag <= 0)})} onClick={()=>updatePayment('wechat')}>
                                    <img className="w-100" src={payment_wechat} alt="" />
                                </div>
                            </div>
                        </div>
                        {/* When Sell Token and more then 1 union card as payment , select 1 card */}
                        {tab == 1 && can_card >= 1 && me.payment_method.filter((p)=>p.type=='card').length > 1 &&
                            <div className='d-flex flex-column gap-1'>
                                <label ><span className="text-danger">* </span>银联卡</label>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue={me.payment_method.filter((p)=>p.type=='card')[0].id} name="card-option" onChange={(e)=>setTargetCard(e.target.value)}>
                                        {
                                            me.payment_method.map((p)=>{
                                                if(p.type == 'card'){
                                                    return <FormControlLabel value={p.id} control={<Radio />} label={p.payee_account} />
                                                }
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        }
                        {tab == 1 && 
                            <div className="d-flex flex-column gap-2 align-items-start">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{t('subpassword')}</label>
                                <input style={{maxWidth:250}} className="w-100 p-2" id="subpassword" name="subpassword" value={subpassword} placeholder={t('subpasswordplaceholder')} onChange={(e)=>setSubPassword(e.target.value)}/>
                            </div>
                        }
                        <div className="d-flex justify-content-center gap-5">
                            <button className="bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitTransfer}>{t('submit')}</button>
                            <button className="bg-white border border-1 text-black px-5 py-2 rounded-1" onClick={reset}>{t('reset')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewTask 