import * as React from 'react';
import info from '../assets/info-icon.jpg'
import profile from '../assets/profile_pic.jpg'
import { useTranslation } from 'react-i18next'

import { useCallback, useEffect, useState } from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ResponsiveChartContainer } from '@mui/x-charts';
import { useLocation } from "react-router-dom";
import { useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import http from '../lib/http'
import Store from 'store'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Header from "../components/header"
const base_url = `${process.env.REACT_APP_API_URL}` ;
const binding_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/binding-list`;
const transfer_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/transfer-info`;
const upload_media_url=`${process.env.REACT_APP_API_URL}/media/upload`;
const update_avatar_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/set-avatar`;
const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/me`;
let series = [];
let balanceDate=[];
let userData={};  
let myRate={};
const Dashboard = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date()); 
  const [endDate, setEndDate] = useState(new Date()); 

  const [data, setData] = useState([]);
  const [userName, setUserName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [userData, setUserData] = useState(false);
  const [myRate, setMyRate] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [direction,setDirection] = useState('');
  const [rows, setRows] = useState([]);
  const [balance, setBalance] = useState("0");
  const [locked_goal, setLocked] = useState("0");
  const [available_usdt, setAvailable] = useState("0");
  const [charts, setChart] = useState(series);
  const [avatar_url, setAvatarUrl] = useState(profile);
  
  var now = new Date();
  var date = now.getFullYear().toString() + "年"+ 
    (now.getMonth() + 1).toString().padStart(2, '0') +"月"+ 
    now.getDate().toString().padStart(2, '0') +"日"+
    now.getHours().toString().padStart(2, '0') + ":"+ 
    now.getMinutes().toString().padStart(2, '0')+ ":"+ 
    now.getSeconds().toString().padStart(2, '0');

  function preventDefault(event) {
    event.preventDefault();
  }

  useEffect(async() => {
    let result= await http({ url: url, method: 'post'}).then(response=>{
      if(response.errorCode!==undefined){
        alert(t('notinwhitelist'));
        Store.remove('user')
        window.location.href = '/'
      }

      if(response.data.avatar != null){
        setAvatarUrl(checkBaseUrl(response.data.avatar));
      }
      let mybalance=(parseFloat(response.data.available_usdt)+parseFloat(response.data.usdt)).toFixed(2);
      setBalance(mybalance);
      setLocked(parseFloat(response.data.locked_goal).toFixed(2));
      setAvailable(parseFloat(response.data.available_usdt).toFixed(2));
      setUserName(response.data.name);
      setWalletAddress(response.data.wallet_address);
      setMyRate({
        api_withdraw:response.data.config.api_withdraw,
        api_fasttrans:response.data.config.api_fasttrans,
        transaction_withdraw_fee:response.data.config.transaction_withdraw_fee,
      })
    });

    await http({ url: transfer_url, method: 'post'}).then(response=>{
      if(response.data && response.data.length!=0){
        balanceDate = response.data.date;
        setUserData(response.data);
        series = [
          {
            type: 'bar',
            stack: '',
            yAxisKey: 'eco',
            data: response.data.in_balance,
            label:"用户充值",
            color:"rgb(110 171 233)"
          },
          {
            type: 'bar',
            stack: '',
            yAxisKey: 'eco',
            data: response.data.out_balance,
            label:"用户",
            color:"rgb(235 146 64)",
          },
          {
            type: 'line',
            yAxisKey: 'pib',
            color: 'red',
            data: response.data.binding_data,
            label:"用户绑定",
            color:"#d85757",
          },
        ];
      }else{
        if(response.errorCode!==undefined){
          alert("不在白名单禁止使用。");
          Store.remove('user')
          window.location.href = '/'
      }
      }
    }); 
  
    await http({ url: binding_url, method: 'post'}).then(response=>{
        console.log(response)
        if(response.data && response.data.length!=0){
          updateTableDate(response.data);
        }else{
          console.log('binding_url wrong',response);
        }
      });        
  },[]);

  const updateTableDate = (_data) => {
    const temp_row = _data;
    setRows(temp_row);
  }

  async function updateAvatar(_avatar) {
    const formData = new FormData();
    formData.append('file', _avatar.target.files[0]);
  
    try {
      const response = await fetch(upload_media_url, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload image');
      }
  
      const data = await response.json();
      console.log('upload_media_url', data);
      
      try {
        await http({ url: update_avatar_url, method: 'post',params:{media:data.data.url}}).then(response=>{
          console.log(response)
          if(response.status == 0 )window.location.reload();
        });   
      } catch (e) {
        console.error('Error updating avatar:', e.message);
      }
  
    } catch (error) {
      console.error('Error uploading image:', error.message);
    }
  }

  function massageStatus(status){
    if(status=='active')
      return '启动'
    else
      return '暂停'
  }

  function erc20AddressShortForm(_address){
    let shortAddress = _address.substring(0, 6) + "..." + _address.substring(_address.length-6);
    return shortAddress;
  }

  function checkBaseUrl(_url){
    if(!_url)return _url;
    console.log('checkBaseUrl')
    if(_url.includes(base_url) == false){
      return base_url + _url
    }
    return _url
  }
  
  function copyToClipboard(text){
    navigator.clipboard.writeText(text);
  }
  
  return (
    <div className="d-flex">
        <Header></Header>
        <div className="w-100 p-4 d-flex flex-column gap-2 vh-100 overflow-auto">
            <h4 className='text-dark-blue'>{t('dashboard')}</h4>
            {userData && <div className="w-100 d-flex flex-column flex-lg-row gap-4">
              <div className='w-100 bg-white p-4 d-flex flex-column gap-4 rounded-2 shadow'>
                <div className="d-flex flex-column gap-2">
                  <div className='d-flex justify-content-between align-items-start'>
                    <img style={{width:100,height:100,objectFit:'cover'}} src={avatar_url} className="border rounded-circle" alt="" />
                    <input id="upload-image-btn" style={{width:0}} type="file" onChange={updateAvatar} />
                    <div role="button" className="d-flex gap-2 align-items-center border border-1 p-2" onClick={()=>document.getElementById('upload-image-btn').click()}>
                      <i className="fa-solid fa-image"></i>
                      <span style={{fontSize:14}}>{t('changeavatar')}</span>
                    </div>
                  </div>
                  <span className='fw-bolder fs-4'>{t('loginname')}：{userName}</span>
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="pb-2">
                    <span className='fw-bolder fs-4 pb-2 border-bottom border-5 border-dark-blue'>{t('accountoverview')}</span>
                  </div>
                  <div className="d-flex gap-2 align-items-center" role="button" onClick={()=>copyToClipboard(walletAddress)}>
                    <span className="fw-bolder">{t('walletaddress')} : {erc20AddressShortForm(walletAddress)}</span>
                    <i className="fa-solid fa-copy"></i>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className='py-4 border-bottom d-flex justify-content-between'>
                    <span className="fw-bolder">{t('numberofrechargeusers')}</span>
                    <span className=''>{userData.inflow_individual}</span>
                  </div>
                  <div className='py-4 border-bottom d-flex justify-content-between'>
                    <span className="fw-bolder">{t('numberofusersissued')}</span>
                    <span className=''>{userData.outflow_individual}</span>
                  </div>
                  <div className='py-4 border-bottom d-flex justify-content-between'>
                    <span className="fw-bolder">V币{t('balance')}</span>
                    <span className=''>{balance}</span>
                  </div>
                  <div className='py-4 border-bottom d-flex justify-content-between'>
                    <span className="fw-bolder">{t('available')}{t('balance')}</span>
                    <span className=''>{available_usdt}</span>
                  </div>
                  <div className='py-4 border-bottom d-flex justify-content-between'>
                    <span className="fw-bolder">{t('frozen')}{t('amount')}</span>
                    <span className=''>{locked_goal>0?locked_goal:0}</span>
                  </div>
                  <div className='py-4 border-bottom d-flex gap-2 justify-content-between'>
                    <span className="fw-bolder w-25">{t('myrate')}</span>
                    <div>
                      <span>{t('withdrawal')} : {myRate.transaction_withdraw_fee}% | </span>
                      <span>{t('issuance')} : {myRate.api_withdraw}% | </span>
                      <span>{t('deposit')} : {myRate.api_fasttrans}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex flex-column flex-lg-row gap-4 flex-lg-wrap">
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('playerrecharge')} ({userData.total_user_inflow_sum}{t('transactions')})</span>
                    <span>{parseFloat(userData.total_user_inflow)}/{parseInt(userData.total_user_inflow_fee)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('playerwithdrawals')} ({userData.total_user_outflow_sum}{t('transactions')})</span>
                    <span>{parseFloat(userData.total_user_outflow)}/{parseInt(userData.total_user_outflow_fee)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('merchantcashwithdrawal')} ({userData.total_my_outflow_sum}{t('transactions')})</span>
                    <span>{parseFloat(userData.total_my_outflow)}/{parseInt(userData.total_my_outflow_fee)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('merchanttransfer')} ({userData.total_my_inflow_sum}{t('transactions')})</span>
                    <span>{parseFloat(userData.total_my_inflow)}/{parseInt(userData.total_my_inflow_fee)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('totaldepositdiscounttoday')}</span>
                    <span>{parseFloat(userData.today_user_deposit_discount||0)}/{userData.today_deposit_offer_limit}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('totalwithdrawdiscounttoday')}</span>
                    <span>{parseFloat(userData.today_user_withdraw_discount||0)}/{userData.today_withdraw_offer_limit}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('totalamountcollectedtoday')}</span>
                    <span>{parseFloat(userData.today_my_inflow||0)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('todaytotalwithdrawalamount')}</span>
                    <span>{parseFloat(userData.today_my_outflow||0)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('todayrechargeamount')}</span>
                    <span>{parseFloat(userData.today_user_inflow||0)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('volumeissuedtoday')}</span>
                    <span>{parseFloat(userData.today_user_outflow||0)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('balanceinpendingorder')}</span>
                    <span>{parseFloat(userData.task_balance||0)}</span>
                  </div>
                </div>
                <div style={{minWidth:320}} className="flex-fill p-4 bg-white d-flex gap-4 justify-content-between align-items-center rounded-2 shadow">
                  <img style={{width:50,height:50}} src={info} alt="" />
                  <div className='d-flex flex-column align-items-end'>
                    <span className="fw-bolder">{t('transactionamount')}</span>
                    <span>{userData.order_balance||0}</span>
                  </div>
                </div>
              </div>
            </div>}

            {/* <div className="w-100 d-flex flex-column flex-md-row gap-2">
              <div className='bg-white border rounded-2 p-3 d-flex flex-column gap-2 justify-content-center align-items-center' style={{minWidth:250}}>
                    <span className='h1'>余额</span>
                    <Typography component="p" variant="h4">
                      {balance}
                    </Typography>
                    <Typography color="text.secondary" >
                      於 {date}
                    </Typography>
              </div>
              <div className="w-100 bg-white border rounded-2">
              <div className="pt-4 w-100 d-flex flex-column justify-content-center align-items-center">
                    {series.map(el => 
                      el.label &&
                      <div className="d-flex gap-2 align-items-center">
                        <div className="border" style={{width:12,height:12,backgroundColor:el.color}}>
                        </div>
                        <span value={el.label} key={el.label}> {el.label} </span>
                      </div>
                    )}
                  </div>
                  <ResponsiveChartContainer sx={{ width: '100%' }} series={series} height={300}
                    xAxis={[
                        {id: 'years',data: balanceDate,
                        scaleType: 'band',valueFormatter: (value) => value.toString(),
                        },
                    ]}
                    yAxis={[
                        {id: 'eco',scaleType: 'linear',},
                        {id: 'pib',scaleType: 'linear',},
                    ]}
                    
                  >
                    <BarPlot />
                    <LinePlot />
                    <ChartsXAxis label="年份" position="bottom" axisId="years" />
                    <ChartsYAxis label="充值提现" position="left" axisId="eco" />
                    <ChartsYAxis label="绑定人数" position="right" axisId="pib" />
                  </ResponsiveChartContainer>
              </div>
            </div>
            <div className='bg-white'>
              <TableContainer component={Paper}>
                    <Table className="w-100" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">用户</TableCell>
                            <TableCell align="right">V币量</TableCell>
                            <TableCell align="right">状态</TableCell>
                            <TableCell align="right">更新时间</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell align="right">{row.user.nickname}</TableCell>
                            <TableCell align="right">{(parseFloat(row.user.usdt)+parseFloat(row.user.available_usdt)).toFixed(2)}</TableCell>
                            <TableCell align="right">{massageStatus(row.status)}</TableCell>
                            <TableCell align="right">{row.updated_at}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div> */}
        </div>
    </div>
  )
}

export default Dashboard