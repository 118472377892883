import {Toast } from 'antd-mobile/2x'
import {useEffect, useState,useMemo } from 'react'
import Header from '../components/header'
import http from '../lib/http'
import './transfer.scss'
import { useTranslation } from 'react-i18next'
import Store from 'store'


const Transfer = () => {
    const { t } = useTranslation();

    const url = `${process.env.REACT_APP_API_URL}/agentbackend/user/site-transfer`;
    const me_url =`${process.env.REACT_APP_API_URL}/agentbackend/user/me`;

    const [me, setMyInfo] = useState({})
    const [account, setAccount] = useState('')
    const [amount, setAmount] = useState('')
    const [fee, setFee] = useState(0)
    const [sub_password, setSubPassword] = useState('')
    const [ga_password, setGaPassword] = useState('')
    const [tab, setTab] = useState(0)
    const [myAvailableUsdt, setMyAvailableUsdt] = useState(0)
    const [heldAvailableUsdt, setHeldAvailableUsdt] = useState(0)
    const [availableUsdt, setAvailableUsdt] = useState(0)
    const [myUsdt, setMyUsdt] = useState(0)

    //submit whitelist function
    const submitTransfer = async()=>{
        if(!account){
            alert(t('inputwalletaddress'))
            return ;
        }
        if(!amount || amount<=0){
            alert(t('pleaseinputtransferamount'))
            return ;
        }
        if(!sub_password){
            alert(t('pleaseinputsubpassword'))
            return ;
        }
        if(!ga_password){
            alert(t('pleaseinputgapassword'))
            return ;
        }
        
        var params = {
            account: account,
			amount: amount,
			sub_password: sub_password,
            tab: tab,   //tab 0 = 人工下发申请 , tab 1 = 商户互转
            ga_code: ga_password
        };
        console.log(params);

        await http({ url: url, method: 'post',params:params}).then(response=>{
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.status == 0 && response.message == "ok"){
                Toast.show({
                    icon: 'success',
                    content: t('transfersuccess'),
                    maskClickable: true
                })
                reset();
                return;
            }
            if(response.status == 1 && response.message == "Ga Code Error"){
                Toast.show({
                    icon: 'fail',
                    content: t('gapasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            if(response.status == 101 && response.message == "incorrect sub password"){
                Toast.show({
                    icon: 'fail',
                    content: t('subpasswordincorrect'),
                    maskClickable: true
                })
                return;
            }
            Toast.show({
                icon: 'fail',
                content: t('transferfailed'),
                maskClickable: true
            })
        });  
    }

    function reset(){
        setAccount('')
        setAmount('')
        setFee(0)
        setSubPassword('')
        setGaPassword('')
    }

    function updateAmount(_amount){
        setAmount(_amount);
        if(_amount == ''){
            setFee(0);
            return;
        }
        
        if(tab === 0){  //人工下发
            setFee(Number(_amount) * me.config.api_fasttrans);
        }else if(tab === 1){    //商户互转
            const a = availableUsdt - Number(_amount) ;
            let feerate = me.config.transfer_fee ;
            if(a<0){
                feerate = me.config.transfer_high_fee ; 
            }			
            console.log("feerate",feerate,Number(_amount))	;
            console.log(feerate * Number(_amount));
            setFee(feerate * Number(_amount) );
        }
    }

    useEffect(async() => {
        await http({ url: me_url, method: 'post'}).then(response=>{
            console.log("auth me",response);
            if(response.status == 0){
                setMyInfo(response.data);
                console.log(Number(response.data.available_usdt),Number(response.data.held_available_usdt))
                setMyAvailableUsdt(Number(response.data.available_usdt))
                setHeldAvailableUsdt(Number(response.data.held_available_usdt))
                setAvailableUsdt(Math.max(Number(response.data.available_usdt)-Number(response.data.held_available_usdt),0))
                setMyUsdt(Number(response.data.usdt||0)+availableUsdt);
                console.log('done');
            }
            
        });
    },[])
    

    return (
        <div className="d-flex">
            <Header></Header>
            <div style={{maxWidth:800}} className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                <h4  className="text-dark-blue">{t('transfer')}</h4>
                <div className="d-flex flex-column">
                    <div className="d-flex bg-white rounded-top-3 overflow-hidden border-bottom">
                        <div role="button" className={'w-50 p-3 text-center ' + (tab===0?'bg-dark-blue text-white':'')} onClick={()=>{setTab(0);reset();}}>
                            {t('manualissuanceofapplication')}
                        </div>
                        <div role="button" className={'w-50 p-3 text-center ' + (tab===1?'bg-dark-blue text-white':'')} onClick={()=>{setTab(1);reset();}}>
                            {t('merchanttransfer')}
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-4 bg-white p-4">
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{tab===0?t('memberaddress'):t('recipientaddress')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="account" name="account" value={account} placeholder={t('inputwalletaddress')} onChange={(e)=>setAccount(e.target.value)}/>
                        </div>
                        <div className="w-100 d-flex flex-column gap-2">
                            <div className="d-flex flex-row gap-2 align-items-center">
                                <label style={{minWidth:120}}><span className="text-danger">* </span>{tab===0?t('quantityissued'):t('quantitytransfer')}</label>
                                <input style={{maxWidth:500}} className="w-100 p-2" id="amount" name="amount" type="number" value={amount} placeholder="0" onChange={(e)=>updateAmount(e.target.value)}/>
                            </div>
                            <div className='d-flex gap-2'>
                                <label style={{minWidth:120}}></label>
                                <label>{t('available')}:{availableUsdt>0?Number(availableUsdt).toFixed(2):0}</label>
                                {availableUsdt-amount<0 && <label>{t('secondary')}{t('available')}:{myUsdt>0?Number(myUsdt).toFixed(2):0}</label> }
                            </div> 
                        </div>
                        <div className="d-flex flex-row gap-2 align-items-center">
                                {tab === 0 && <label style={{minWidth:120}}><span className="text-danger">* </span>{t('fee')} ({me.config?me.config.api_fasttrans*100:0}%)</label>}
                                {tab === 1 && <label style={{minWidth:120}}><span className="text-danger">* </span>{t('fee')} ({me.config?availableUsdt - amount > 0?me.config.transfer_fee*100:me.config.transfer_high_fee*100:0}%)</label>}
                                <input style={{maxWidth:500}} className="w-100 p-2" id="fee" name="fee" value={fee} placeholder="0" disabled/>
                            </div>
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('subpassword')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="sub_password" name="sub_password" value={sub_password} placeholder="" onChange={(e)=>setSubPassword(e.target.value)}/>
                        </div>
                        <div className="d-flex flex-row gap-2 align-items-center">
                            <label style={{minWidth:120}}><span className="text-danger">* </span>{t('gapassword')}</label>
                            <input style={{maxWidth:500}} className="w-100 p-2" id="ga_password" name="ga_password" value={ga_password} placeholder="输入动态验证码" onChange={(e)=>setGaPassword(e.target.value)}/>
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                            <button className="bg-dark-blue text-white border-0 px-5 py-2 rounded-1" onClick={submitTransfer}>{t('submit')}</button>
                            <button className="bg-white border border-1 text-black px-5 py-2 rounded-1" onClick={reset}>{t('reset')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transfer 